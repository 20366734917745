export class IniciarOtp {
    constructor(
        public cliente: string,
        public servicio: string,
        public documento: number,
        public nombres: string,
        public primer_apellido: string,
        public segundo_apellido: string,
        public celular: number,
        public fecha_expedicion: number
    ){}
}