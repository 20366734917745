import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Global } from '../../../../services/global';
import { Solicitud } from 'src/app/models/solicitud';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-request-new',
  templateUrl: './request-new.component.html',
  styleUrls: ['./request-new.component.css'],
  providers: [GeneralService]
})
export class RequestNewComponent implements OnInit {

  public fechaIngreso;
  public fechaRetiro;
  public diasLiquidacion: any;
  public diasTrabajo: any;
  public fechaUltimoAno;
  public sueldo: string;
  public promedioExtras: string;
  //public otros: string;
  public transporte: number;
  public totalSalario: number;
  public cesantias: number;
  public intCesantias: number;
  public prima: number;
  public vacaciones: number;
  public liquidacion: number;
  public month;
  public formatNumber;
  public newData;
  
  /* Variables de respuesta */
  public response;
  public meses = [{'name': 1+' mes', 'value': 1}];
  public Arr: Array<any>;
  public selectTime;

  /* Variables de calculo */
  public calcCesantia;
  public calcIntCesantias; 
  public calcPrima; 
  public calcVacaciones;
  public cuota;
  public monto: string;
  public plazo: number;
  public cargos;
  public cuatroPorMil;
  public valor;

  /*User indentity*/
  public identity;

  public solicitud;
  public calculate = false;
  public request: boolean;

  constructor(
    private _router: Router,
    private _generalService: GeneralService
  ) {

    this.request = false;
  	this.loadUser(); 

  	this.solicitud = new Solicitud(null, null, null, null, null, null, null, null, null, null)
  	
    this.transporte = 0;

    let date = new Date();

    let day = date.getDate();
    this.month = date.getMonth() + 1;
    let year = date.getFullYear();

    this.fechaRetiro = year + '/' + '0' + this.month + '/' + day;

    this.formatNumber = {
      separador: ".", // separador para los miles
      sepDecimal: ',', // separador para los decimales
      formatear: function (num) {
        num += '';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
          splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return this.simbol + splitLeft + splitRight;
      },
      new: function (num, simbol) {
        this.simbol = simbol || '';
        return this.formatear(num);
      }
    }

    this.getValidate();

  }

  ngOnInit() {
    
  }

  onSubmit(form) {

    this.generateDate();

    this.calculate = true;

    let _fechaIngreso = new Date(this.fechaIngreso);
    let _fechaRetiro = new Date(this.fechaRetiro);

    if (this.diasLiquidacion > 180 && this.diasLiquidacion < 360) {
      var contprima = (this.diasLiquidacion - 180);
    }

    let ano_ingreso = this.fechaIngreso.split('-');
    ano_ingreso = ano_ingreso[0];
    let fecha = new Date();
    let ano_actual = fecha.getFullYear();

    let diasLiquidacion: any;

    diasLiquidacion = _fechaRetiro.getTime() - new Date(ano_actual + '-01-01').getTime();
    diasLiquidacion = Math.round(diasLiquidacion / (1000 * 60 * 60 * 24));

    let fechafin = new Date(this.fechaRetiro);
    //dias laborados segun el año actual
    var diaslab = fechafin.getTime() - _fechaIngreso.getTime();
    this.diasTrabajo = Math.round(diaslab / (1000 * 60 * 60 * 24));

    let calcSueldo = this.sueldo;
    let calcPromedioExtras = this.promedioExtras;

    calcSueldo = this.sueldo.replace(/\./g, '');
    calcPromedioExtras = this.promedioExtras.replace(/\./g, '');

    /*if (parseInt(calcSueldo) > 1656232) {
      this.transporte = 0;
    } else {
      this.transporte = 97032;
    }*/

    let sumTotalSalario = parseInt(calcSueldo) + parseInt(calcPromedioExtras) + this.transporte;
    this.totalSalario = this.formatNumber.new(sumTotalSalario);

    this.calcCesantia = (sumTotalSalario * this.diasTrabajo) / 360;
    this.calcIntCesantias = Math.round((this.calcCesantia * 0.12 * this.diasTrabajo) / 360);
    this.calcPrima = (sumTotalSalario * this.diasTrabajo) / 360;
    this.calcVacaciones = (sumTotalSalario * diasLiquidacion / 720);

    let sumliquidacion = Math.round(this.calcCesantia + this.calcIntCesantias + this.calcPrima + this.calcVacaciones);

    this.liquidacion = this.formatNumber.new(sumliquidacion);
    this.response = this.monthValidate();

    let i = 1;

  }

  monthValidate() {
    switch (this.month) {
      case 1:
        this.plazo = 10;
        return this.calcule(130, 130, 10);
      case 2:
        this.plazo = 10;
        return this.calcule(130, 130, 10);
      case 3:
        this.plazo = 10;
        return this.calcule(100, 100, 10);
      case 4:
        this.plazo = 10;
        return this.calcule(100, 100, 10);
      case 5:
        this.plazo = 10;
        return this.calcule(20, 100, 10);
      case 6:
        this.plazo = 10;
        return this.calcule(0, 100, 10);
      case 7:
        this.plazo = 10;
        return this.calcule(100, 100, 10);
      case 8:
        this.plazo = 10;
        return this.calcule(100, 100, 10);
      case 9:
        this.plazo = 10;
        return this.calcule(100, 60, 10);
      case 10:
        this.plazo = 8;
        return this.calcule(100, 20, 10);
      case 11:
        this.plazo = 8;
        return this.calcule(100, 10, 10);
      case 12:
        this.plazo = 6;
        return this.calcule(100, 0, 9);
    }
  }
  calcule(prima, cesantias, vacaciones) {

    this.selectTime = this.plazo;

    let response = [];

    let interes = parseFloat('2,2');
    interes = interes / 100;
    // Monto aprobado
    let totalPrima = this.calcPrima * (prima / 100);
    let totalCesantia = this.calcCesantia * (cesantias / 100);
    let totalVacaciones = this.calcVacaciones * (vacaciones / 100);

    let _monto = Math.round(totalPrima + totalCesantia + totalVacaciones);

    _monto = this.calcMonto(_monto);
    
    let tasaInteres = (interes*100);
    let tasaDeInteres = String(tasaInteres);
    localStorage.setItem('interes', tasaDeInteres);

    // Calculo de cuota
    this.calcCuota();

    response['monto'] = this.formatNumber.new(this.monto);
    response['_monto'] = parseInt(this.monto.replace(/\./g, ''));
    response['cuota'] = this.formatNumber.new(this.cuota);

    return response;
  }

  calcCuota() {
    
    let _monto = this.monto.replace(/\./g, ''); 

    const elevado = Math.pow(1+(2.2/100), this.selectTime);
    const arriba = parseInt(_monto) * elevado * (2.2/100);
    const abajo = elevado - 1;

    this.cuota = this.formatNumber.new(Math.round(arriba / abajo));

  }

   calcMonto(monto) {
    
    let _cuatroPorMil = Math.round((monto * 4) / 1000);

    monto = Math.round(monto - 25850 - _cuatroPorMil); 

    this.monto = monto.toString();
    this.cuatroPorMil = this.formatNumber.new(_cuatroPorMil.toString());
    this.monto = this.formatNumber.new(this.monto);

    this.valor = monto;
    return monto;

  }

  onKey(e){
    
     if (e.type == 'text') {
      this.format(e);

      let monto = parseInt(e.value.replace(/\./g, ''))

      if(monto > 100000) {
        this.calcMonto(monto);
        this.calcCuota();
      }

    } else {
      this.calcCuota();
    }

  }

  format(input)
  {
    var num = input.value.replace(/\./g,'');
    if(!isNaN(num)){
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
      num = num.split('').reverse().join('').replace(/^[\.]/,'');
      input.value = num;
    }

  }

  loadUser() {

    this.identity = this._generalService.getIdentity();
  
  }

  send(form) {

  	this.solicitud.cuotesNumber = this.selectTime;
  	this.solicitud.requestedAmount = this.monto.replace(/\./g,'');
  	let json = this._generalService.dataJson(this.solicitud, "3");

    localStorage.setItem('key', '1');
  	
  	this._generalService.api(json).subscribe(
  		response => {
  			this._router.navigate(['/main/user/credits']);
  		}, 
  		error => {
  			console.log(<any>error);
  		}
  	);

  }

  generateDate() {
    // Se define la fecha actual y se guarda en la variable del model de fecha de desembo
    let currentDate = new Date();
    this.solicitud.disbursementDate = this.formatDate(currentDate);

    // Se define la fecha actual y se le agregan 30 dias. se guarda en la variable del model de fecha de primer pago
    currentDate.setDate(currentDate.getDate() + 30);
    this.solicitud.firstPaymentDate = this.formatDate(currentDate);

  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  getValidate(){
    let json = Global.json;
    //onsole.log(this.identity.id);
    json.body.serviceID.id = '19';
    let personID = this.identity.id;
    let inputParameters = {"name": "id", "value":personID};

    json.body.inputParameters.push(inputParameters);

    this._generalService.api(json).subscribe(
      response => {
        //console.log(response);
        if(response.body.outputParameters.value) {
          
          this.request = true;
        } else {
          
          this.request = false;
        }

      },
      error => {
        console.log(<any>error);
      }
    )
  }

  toggle() {
    this.newData = !this.newData;
  }

  getCargos() {

    let cargos = localStorage.getItem('cargos')

    if (cargos != undefined && cargos != null && cargos != '') {
      this.cargos = cargos;

    } else {

      let json = Global.json;
      json.body.serviceID.id = "20";
      this._generalService.api(json).subscribe(
        response => {
          localStorage.setItem('cargos', JSON.stringify(response.body.outputParameters.value));
        },
        error => {
          console.log(<any>error)
        }
      );
    }

  }

  asignarTransporte(e) {
    //console.log(e.target.checked);

    if(e.target.checked) {
      this.transporte = 97032;
    } else {
      this.transporte = 0;
    }
  }


}


