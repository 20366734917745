export class Solicitud {

    constructor(
        public monto_solicitado: string,
        public persona: string,
        public fecha_primer_pago: string,
        public producto: number,
        public destino: number,
        public frecuencia_de_pago: number,
        public numero_de_cuotas: number,
        public tasa_de_interes: number,
        public fecha_de_desembolso: string,
        public convenio_solicitud: string
    ) {}
}