export class Otp {
    constructor(
        public cliente: string,
        public servicio: string,
        public regValidacion: string,
        public idTransaccionOTP: string,
        public codigo: string,
        public identificacion,
        public email: string,
        //public momento: string,
        public solicitud: string,
        public persona: number
    ) {}
}