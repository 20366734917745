import { Component, OnInit, ɵConsole } from '@angular/core';

import { Global } from '../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent implements OnInit {

  public identity;
  public fecha;
  public list;
  public load: boolean;
  public search_load: boolean;
  public tipo_pago;
  public list_empty: boolean;
  public cronometro;
  public clean_cronometro;

  constructor(
    private _generalService: GeneralService,
  ) {

    this.identity = this._generalService.getIdentity();
    this.load = true;
    this.search_load = false;
    this.tipo_pago = 0;
    this.fecha = new Date().toISOString().split('T')[0];
    /* console.log(this.fecha); */

  }

  ngOnInit() {
    this.getTransactionsListByPerson();
  }

  getTransactionsListByPerson() {

    let json = Global.json;
    json.body.serviceID.id = '46';
    json.body.inputParameters.push({ 'name': 'persona', 'value': this.identity.persona });
    json.body.inputParameters.push({ 'name': 'fecha', 'value': this.fecha });
    json.body.inputParameters.push({ 'name': 'tipo_pago', 'value': this.tipo_pago });
    json.body.inputParameters.push({ 'name': 'check', 'value': 'null' });
    
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          json.body.inputParameters = [];
          this.list = JSON.parse(response.body.outputParameters.value);
          this.list_empty = true;

          if (this.list.length == 0) {
            this.list_empty = false;
          }

          var arr: Array<any> = [];

          this.list.forEach(element => {
            if (element.status_kushki == 'PENDIENTE' || element.status_kushki == 'INICIALIZADA') {
              arr.push({ 'numero_ticket': element.numero_ticket });
            }
          });
          //console.log(arr);
          if (arr.length > 0) {
            this.checkStatus(arr);
          }

        }

        this.load = false;

      },
      error => {
        console.log(<any>error)
      }
    );
  }

  search(form) {

    this.search_load = true;

    this.getTransactionsListByPerson();
    this.search_load = false;


  }

  checkStatusTimer(json) {
    let n = 0;
    //console.log("TIMER");
    this.cronometro = setInterval(() => {
      
      this._generalService.api(json).subscribe(
        response => {
          if (response) {
            //console.log(n++, new Date().toISOString());
            this.list = JSON.parse(response.body.outputParameters.value);
            
            var arr: Array<any> = [];
  
            this.list.forEach(element => {
              if (element.status_kushki == 'PENDIENTE' || element.status_kushki == 'INICIALIZADA') {
                arr.push({ 'numero_ticket': element.numero_ticket });
              }
            });

            //console.log(arr, new Date().toISOString());
            if (arr.length > 0) {
              
              this.clean_cronometro = false;
              //this.checkStatusTimer(json)
            } else {
              this.clean_cronometro = true;
            }
  
          }
  
        },
        error => {
          console.log(<any>error)
        }
      );

      if(this.clean_cronometro){
        clearInterval(this.cronometro);
      }

      /* if(n == 3){
        console.log("end timer");
        clearInterval(this.cronometro);
      } */

    }, 180000); //180000

  }

  checkStatus(obj) {
   
    let json = Global.json;
    json.body.serviceID.id = '46';
    json.body.inputParameters.push({ 'name': 'persona', 'value': this.identity.persona });
    json.body.inputParameters.push({ 'name': 'fecha', 'value': this.fecha });
    json.body.inputParameters.push({ 'name': 'tipo_pago', 'value': this.tipo_pago });
    json.body.inputParameters.push({ 'name': 'check', 'value': obj });

    this._generalService.api(json).subscribe(
      response => {
        if (response) {
         
          this.list = JSON.parse(response.body.outputParameters.value);
          
          var arr: Array<any> = [];

          this.list.forEach(element => {
            if (element.status_kushki == 'PENDIENTE' || element.status_kushki == 'INICIALIZADA') {
              arr.push({ 'numero_ticket': element.numero_ticket });
            }
          });
          
          if (arr.length > 0) {
            this.checkStatusTimer(json)
          } 

        }

      },
      error => {
        console.log(<any>error)
      }
    );

  }

}
