import { Component, OnInit } from '@angular/core';
import { Global } from '../../../../services/global';
import { Solicitud } from 'src/app/models/solicitud';

import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css'],
  providers: [GeneralService],
})
export class CreditsComponent implements OnInit {
  public solicitud: Solicitud;
  public solicitudes: any;
  public identity;
  public key;
  public load;
  public mensajeEstado: any;

  constructor(
    private _generalService: GeneralService,
    ) {
      this.identity = this._generalService.getIdentity();
      this.load = true;
    }

  ngOnInit() {
    this.getSolicitudes();
    this.keyProcess();
  }

  getSolicitudes(){
    let json = Global.json;

    json.body.serviceID.id = '14';
    let personID = this.identity.id;
    let inputParameters = {"name": "id", "value":personID};

    json.body.inputParameters.push(inputParameters);

    this._generalService.api(json).subscribe(
      response => {
        if(response) {
          /* console.log(response.body.outputParameters); */
          this.solicitudes = JSON.parse(response.body.outputParameters.value);          
          this.load = false;
          console.log(this.solicitudes);
        }
      },
      error => {
        console.log(<any>error);
      }
    );

  }

  keyProcess() {

    let key = localStorage.getItem('key');

    if(key == '1') {
      this.key = key;

      localStorage.removeItem('key');
    }

  }

  setData(solicitud) {
    console.log(solicitud);
    this._generalService.momento$.emit('firma');
    this._generalService.solicitud$.emit(solicitud.id);
    this._generalService.datos_solicitud$.emit(solicitud);
    sessionStorage.setItem('id_solcitud',solicitud.id);
  }

  setMensajeSolicitud(solicitud){
    let mensaje = '';
    if(solicitud.estado == 'Solicitado'){
      mensaje = 'Dale click aquí para cargar tus documentos';
    }else if(solicitud.estado == 'Aprobado'){
      if(!solicitud.firmado){
        mensaje = 'Dale click aquí para validar tu usuario';
      }
    }

    return mensaje;
  }

}
