import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PersonaDatosPersonalCuenta } from 'src/app/models/PersonaDatosPersonalCuenta';
import { Solicitud } from 'src/app/models/solicitud';
import { Global } from '../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';
import { Labor } from '../../../../models/labor';
import { Login } from 'src/app/models/login';
import { PersonaDatosSolicitud } from 'src/app/models/PersonaDatosSolicitud';
import { Conyuge } from 'src/app/models/Conyugue';
import { InformacionLaboral } from 'src/app/models/InformacionLaboral';
import { Ingresos } from 'src/app/models/Ingresos';
import { Egresos } from 'src/app/models/Egresos';
import { ReferenciasPersonales } from 'src/app/models/ReferenciasPersonales';
import { ReferenciasFamiliares } from 'src/app/models/ReferenciasFamiliares';
import { ActividadEconomica } from 'src/app/models/ActividadEconimica';
import { Carro } from 'src/app/models/Carro';
import { Subscription } from 'rxjs';
import { isNull } from 'util';

@Component({
  selector: 'app-wizard-form',
  templateUrl: './wizard-form.component.html',
  styleUrls: ['./wizard-form.component.css'],
  providers: [GeneralService],
})
export class WizardFormComponent implements OnInit {

  public firstName: string;
  //public json;
  public persona: PersonaDatosPersonalCuenta;
  public login: Login;

  public click = true;
  public bConvenio = false;
  public bFormInfo = false;
  public convenios;
  public email;
  public solicitud: Solicitud;
  public labor: Labor;
  public data;
  public firstPaymentDate;
  public status;
  public message;
  //public persona;
  public currentDate;
  public newData;
  public cargos;
  public checkNum: number;
  public checked: boolean;
  public tipos_identificacion;

  public load: boolean;

  // accordeon
  public collapseTwo: boolean;
  public collapseThree: boolean;
  public collapseFour: boolean;
  public sent: boolean;

  //Variables wpp
  public name;
  public company;

  // DEMO
  public tiempoLabel;

  //CREDIT REQUEST
  public identity;
  public solicitud_simulador;
  public respuesta_solicitud;
  public mostrarCardConyuge: boolean;
  public mostrarCardCarro: boolean;
  public jumping: boolean;
  public jumps: number;
  public card: number;
  public tab: string;
  public ocupacion;

  //SOLICITUD
  public datos_solicitud: PersonaDatosSolicitud;
  /* public conyuge: Conyuge; */
  /* public info_laboral: InformacionLaboral */;
  public ingresos: Ingresos;
  public egresos: Egresos;
  public referencias_familiares: ReferenciasFamiliares;
  public referencias: ReferenciasPersonales;
  public actividad_economica: ActividadEconomica;
  public carro: Carro;
  public bancos;
  public ciudades;
  public estados_civil;
  public cuentas_banco;
  public niveles_academico;
  public tipos_contrato;
  public ocupaciones;
  public convenios_dinamico: Array<any> = [];
  public tipoSolicitudSubscribe: Subscription;
  public tipo_solicitud;
  public nombre_referencias_personales: string;
  public telefono_referencias_personales: string;

  constructor(
    private _router: Router,
    private _generalService: GeneralService,
  ) {

    this.persona = new PersonaDatosPersonalCuenta(null, null, '', '', '', '', '', null, null, null, null);
    this.login = new Login(null, null);
    this.collapseTwo = false;
    this.collapseThree = false;
    this.collapseFour = false;
    this.load = false;
    this.checked = false;
    this.sent = false;
    this.checkNum = 0;

    this.mostrarCardConyuge = false;
    this.jumping = true;
    this.jumps = 0;
    this.card = 1;
    this.ocupacion = null;

    this.referencias = new ReferenciasPersonales(
      null,
      null,
      null,
      null
    );

    /* this.solicitud = new Solicitud(
      monto,
      this.solicitud_simulador.solicitud.persona,
      fecha_primer_pago,
      this.solicitud_simulador.solicitud.producto,
      this.solicitud_simulador.solicitud.destino,
      this.solicitud_simulador.solicitud.frecuencia_de_pago,
      this.solicitud_simulador.solicitud.numero_de_cuotas,
      this.solicitud_simulador.solicitud.tasa_de_interes,
      fecha_de_desembolso,
      this.solicitud_simulador.solicitud.convenio
    ); */

    this.datos_solicitud = new PersonaDatosSolicitud(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      '',
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    /* this.datos_solicitud.nombre_empresa_sin_convenio = true; */
    /* console.log(this.datos_solicitud.nombre_empresa_sin_convenio); */

    /* this.conyuge = new Conyuge(
      null,
      null,
      null,
      null,
      '',
      '',
      null,
      null,
      null
    ); */

    this.actividad_economica = new ActividadEconomica(
      null,
      null,
      null,
      null,
      null,
      null,

    );

    this.ingresos = new Ingresos(
      null,
      null,
      null
    );

    this.egresos = new Egresos(
      null,
      null,
    );

    this.referencias_familiares = new ReferenciasFamiliares(
      null,
      null,
      null,
      null,
      null
    );

    /* this.referencias_personales = new ReferenciasPersonales(
      null,
      null,
      null,
      null
    ); */

    this.carro = new Carro(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0
    );
  }

  ngOnInit() {
    //sessionStorage.removeItem('isCalculated');
    this.topFunction();
    this.getTiposIdentificacion();
    this.getBancos();
    this.getCiudades();
    this.getEstadosCivil();
    this.getTiposCuentaBanco();
    this.getNivelesAcedemico();
    this.getConvenios();
    this.getTiposContrato();
    this.getOcupaciones();
    this.getLocalStorageSolicitud();

    this.tipoSolicitudSubscribe = this._generalService.tipo_solicitud$.subscribe(tipo_solicitud => {
      this.tipo_solicitud = tipo_solicitud;
      //console.log(tipo_solicitud);
    });
    this.ocupacion = 3;
  }

  scroll_a(el: HTMLElement, event) {
    event.preventDefault()
    //console.log(el);
    el.scrollIntoView();
  }

  personalForm(el: HTMLElement = null) {
    /* event.preventDefault(); */
    el.scrollIntoView();
    this.collapseTwo = true;
  }

  onSubmit(form) {
    this.getLocalStorageSolicitud();
    console.log(this.solicitud);
    this.crearUsuario();
  }

  /*signForm() {

    let json = this._generalService.dataJson(this.person, "20");
    
    this._generalService.api(json).subscribe(
      response => {
        
      },
      error => {
        console.log(<any>error);
      }
    )

  }*/

  validateConvenio(e) {

    if (e.target.value == 'null') {
      this.bConvenio = true;
      this.bFormInfo = false;
    } else {
      this.bConvenio = false;
      this.bFormInfo = true;
    }

  }

  getTiposIdentificacion() {

    let json = Global.json;
    json.body.serviceID.id = "26";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.tipos_identificacion = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getConvenio() {
    let json = Global.json;
    json.body.serviceID.id = "7";
    return this._generalService.getConvenio(json).subscribe(
      response => {
        this.convenios = JSON.parse(response.body.outputParameters.value);
      },
      error => {
        console.log(<any>error)
      }
    );
  }

  toggle() {
    this.newData = !this.newData;
  }

  checkInputCheck(e) {

    let checks = document.getElementsByClassName('form-check-input');

    if (e.target.checked) {
      this.checkNum++;
    } else {
      this.checkNum--;
    }

    if (this.checkNum == checks.length) {
      this.checked = true;
    } else {
      this.checked = false;
    }

  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  ngOnDestroy() {
    this.tipoSolicitudSubscribe.unsubscribe();
  }

  getBancos() {

    let json = Global.json;
    json.body.serviceID.id = "29";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.bancos = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );
  }

  getCiudades() {

    let json = Global.json;
    json.body.serviceID.id = "5";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.ciudades = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getEstadosCivil() {

    let json = Global.json;
    json.body.serviceID.id = "30";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.estados_civil = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getTiposCuentaBanco() {

    let json = Global.json;
    json.body.serviceID.id = "31";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.cuentas_banco = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getNivelesAcedemico() {

    let json = Global.json;
    json.body.serviceID.id = "32";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.niveles_academico = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getConvenios() {

    let json = Global.json;
    json.body.serviceID.id = "7";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.convenios = JSON.parse(response.body.outputParameters.value);
          //console.log(this.convenios);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getTiposContrato() {

    let json = Global.json;
    json.body.serviceID.id = "33";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.tipos_contrato = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  changeBackgroud(e, target = null) {
    if (target) {
      target.scrollIntoView();
    }
    target.scrollIntoView();
    let event_target = e.target;
    let parent_element = event_target.parentNode.parentNode.parentNode;

    // Se optiene el elemento con id opened para remover el backgroud
    let last_element = document.getElementById('opened');

    if (last_element.textContent != event_target.textContent) {

      last_element.parentNode.parentElement.className = last_element.parentNode.parentElement.className.replace(/(?:^|\s)bg-primary-af(?!\S)/g, ' bg-white');
      last_element.children[0].className = last_element.children[0].className.replace(/(?:^|\s)collapse-title-white(?!\S)/g, ' collapse-title-af');

    }
    // Si encuentra en el evento la clase collapsed quiere decir que esta cerrada la pestalla del wizard
    if (event_target.classList.contains('collapsed')) {
      //console.log(last_element);
      // Quita el id con valor opened el cual capturara la ventana del wizard que esta abierta
      last_element.removeAttribute('id');

      parent_element.className = parent_element.className.replace(/(?:^|\s)bg-white(?!\S)/g, ' bg-primary-af');
      event_target.className = event_target.className.replace(/(?:^|\s)collapse-title-af(?!\S)/g, ' collapse-title-white');

    } else {

      parent_element.className = parent_element.className.replace(/(?:^|\s)bg-primary-af(?!\S)/g, ' bg-white');
      event_target.className = event_target.className.replace(/(?:^|\s)collapse-title-white(?!\S)/g, ' collapse-title-af');

    }

    event_target.parentElement.setAttribute('id', 'opened');

  }

  next(e, target = null) {
    
    if (target) {
      target.scrollIntoView();
    }
    let event;
    let button_next;

    event = e.target;
    //console.log(event);
    this.card = parseInt(event.attributes.id.value);

    /* if (this.card == 2) this.validarEstadoCivil(); */

    let sum_id = this.card + this.jumps;

    button_next = document.getElementById('c-' + sum_id + '-mb');

    let last_element = document.getElementById('opened');

    last_element.parentNode.parentElement.className = last_element.parentNode.parentElement.className.replace(/(?:^|\s)bg-primary-af(?!\S)/g, ' bg-white');
    last_element.children[0].className = last_element.children[0].className.replace(/(?:^|\s)collapse-title-white(?!\S)/g, ' collapse-title-af');

    /* if(button_next.textContent == " Información del vehículo ") {
      this.listenerTipoSolicitud();
    } */

    button_next.parentNode.parentNode.parentElement.className = button_next.parentNode.parentNode.parentElement.className.replace(/(?:^|\s)bg-white(?!\S)/g, ' bg-primary-af');
    button_next.className = button_next.className.replace(/(?:^|\s)collapse-title-af(?!\S)/g, ' collapse-title-white');

    last_element.removeAttribute('id');
    button_next.parentElement.setAttribute('id', 'opened');

    button_next.removeAttribute('disabled');

    this.jumps = 0;

  }

  validarEstadoCivil() {
console.log("Buenas");
    var sum_id: number;

    if (this.datos_solicitud.estado_civil == 4 || this.datos_solicitud.estado_civil == 2) {
      this.mostrarCardConyuge = true;
      this.jumps = 0;
      sum_id = this.card + this.jumps;
    } else {
      this.mostrarCardConyuge = false;
      this.jumps = 1;
      sum_id = this.card + this.jumps;
    }

    let data_target = 'c-' + sum_id + '-m';
    let a_attribite_next = document.getElementById(this.card.toString());

    if (!this.mostrarCardConyuge) {

      let conyuge_card = document.getElementById('c-2-mb');
      conyuge_card.setAttribute("disabled", "true");

      /* this.conyuge = new Conyuge(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ); */

    }

    a_attribite_next.attributes['data-target'].value = '#' + data_target;
    a_attribite_next.attributes['aria-controls'].value = data_target;

  }

  getLocalStorageSolicitud() {
    this.solicitud_simulador = JSON.parse(sessionStorage.getItem('solicitud'));

    let monto_toString = this.solicitud_simulador.solicitud.monto_solicitado.toString();
    let monto = monto_toString.replace(/\./g, '');
    monto = parseInt(monto);

    let fecha_primer_pago = this._generalService.dateFormated(this.solicitud_simulador.solicitud.fecha_primer_pago);
    let fecha_de_desembolso = this._generalService.dateFormated(this.solicitud_simulador.solicitud.fecha_de_desembolso);

    this.solicitud = new Solicitud(
      monto,
      null,
      fecha_primer_pago,
      this.solicitud_simulador.solicitud.producto,
      this.solicitud_simulador.solicitud.destino,
      this.solicitud_simulador.solicitud.frecuencia_de_pago,
      this.solicitud_simulador.solicitud.numero_de_cuotas,
      this.solicitud_simulador.solicitud.tasa_de_interes,
      fecha_de_desembolso,
      this.datos_solicitud.convenio
    );
    console.log(this.solicitud);
  }

  validateCompany(e) {

    var target = e.target;
    var nombre_empresa = document.getElementById('nombre_empresa');
    var radio_no_empresa = <HTMLInputElement>document.getElementById('radio_no_empresa');

    if (radio_no_empresa.checked) {
      radio_no_empresa.checked = false;
    }
    //var input_nombre_empresa = <HTMLInputElement>nombre_empresa.firstElementChild.firstElementChild.lastElementChild;
    //console.log(nombre_empresa.firstElementChild.firstElementChild.lastElementChild);

    var cargo = document.getElementById('cargo');
    // Si el id es "sin convenio"
    if (target.value == 113) {

      cargo.className = cargo.className.replace(/(?:^|\s)col-12 col-sm-12 col-md-6 col-lg-6(?!\S)/g, 'col-12 col-sm-12 col-md-6 col-lg-3');

      this.datos_solicitud.nombre_empresa_sin_convenio = null;

      nombre_empresa.hidden = false;

    } else {
      cargo.className = cargo.className.replace(/(?:^|\s)col-12 col-sm-12 col-md-6 col-lg-3(?!\S)/g, 'col-12 col-sm-12 col-md-6 col-lg-6');

      this.datos_solicitud.nombre_empresa_sin_convenio = 'null';
      nombre_empresa.hidden = true;
    }

    //console.log(this.datos_solicitud.nombre_empresa_sin_convenio);

  }

  validateOcupacion(e) {
    console.log(e);
  }

  getOcupaciones() {

    let json = Global.json;
    json.body.serviceID.id = "54";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.ocupaciones = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  checkNoExisteMiEmpresa() {

    var nombre_empresa = document.getElementById('nombre_empresa');
    var cargo = document.getElementById('cargo');

    cargo.className = cargo.className.replace(/(?:^|\s)col-12 col-sm-12 col-md-6 col-lg-6(?!\S)/g, 'col-12 col-sm-12 col-md-6 col-lg-3');
    let input_busqueda = document.getElementById('busqueda_convenio') as HTMLInputElement;
    input_busqueda.value = 'Otra';
    this.datos_solicitud.convenio = 'Otra';

    this.datos_solicitud.nombre_empresa_sin_convenio = null;

    nombre_empresa.hidden = false;

  }

  buscarConvenio(buscar) {

    if (buscar.length > 3000) {
      let json = Global.json;
      json.body.serviceID.id = "55";
      json.body.inputParameters.push({ 'name': 'buscar', 'value': buscar });
      this._generalService.api(json).subscribe(
        response => {
          let value = JSON.parse(response.body.outputParameters.value);
          this.convenios_dinamico = value.convenios
          //console.log('ojo');
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  setItem(id_convenio, value) {

    let input_busqueda = document.getElementById('busqueda_convenio') as HTMLInputElement;

    if (id_convenio === 113) {
      /* console.log(this.datos_solicitud.nombre_empresa_sin_convenio); */
      this.checkNoExisteMiEmpresa();
    } else {
      /* console.log(this.datos_solicitud.nombre_empresa_sin_convenio); */
      input_busqueda.value = value;
      //this.datos_solicitud.convenio = input_busqueda;

      var nombre_empresa = document.getElementById('nombre_empresa');
      nombre_empresa.hidden = true;

      var radio_no_empresa = document.getElementById('radio_no_empresa') as HTMLInputElement;
      radio_no_empresa.checked = false;

    }

    let lista_id = document.getElementById('lista_id');

    if (!isNull(lista_id)) {
      lista_id.style.display = 'none';
    }

  }

  crearUsuario() {
    //console.log(this.persona);
    this.load = true;
    this.sent = true;
    let json = this._generalService.dataJson([this.persona, this.login], "1");
    //console.log(json);
    this._generalService.api(json).subscribe(
      response => {

        if (response.status) {
          this.status = 'warning';
          this.message = response.message

        } else {
          this.status = 'success'

          this.message = "Datos guardados con exito";

          this.crearSolicitud();
        }
      },
      error => {
        this.status = 'danger'

        this.message = "Error al crear usuario";
        console.log(<any>error);
      }
    );
    this.load = false;
  }

  crearSolicitud() {
    this.load = true;
    var btn_submit = document.getElementById('btn-submit');
    btn_submit.setAttribute('disabled', 'true');

    this.referencias = new ReferenciasPersonales(
      this.nombre_referencias_personales,
      null,
      this.telefono_referencias_personales,
      null
    );

    /* console.log(this.referencias); */

    this.datos_solicitud.ocupacion = this.ocupacion;
    //convenio
    let convenio = document.getElementById('busqueda_convenio') as HTMLInputElement;    
    this.datos_solicitud.convenio = convenio.value;
      
    let json_solicitud = this._generalService.dataJson([
      this.persona,
      this.datos_solicitud,
      /* this.conyuge, */
      this.actividad_economica,
      this.ingresos,
      this.egresos,
      this.referencias_familiares,
      this.referencias,
      this.solicitud,
      this.carro
    ], "34");

    /* console.log(json) */

    this._generalService.api(json_solicitud).subscribe(
      response => {

        if (response && response.body) {
          this.respuesta_solicitud = JSON.parse(response.body.outputParameters.value);

          if (this.respuesta_solicitud && this.respuesta_solicitud.status == 'success') {
            this.status = 'success';
          } else if (!response.body) {
            this.status = 'warning';
          } else if (!this.respuesta_solicitud.solicitud) {
            this.status = 'warning';
            this.message = 'Ya se encuentra en proceso el número maximo de solicitudes el cual se tiene permitido '
          } else {
            this.status = 'warning';
          }
        } else {
          this.message = response.message;
          this.status = 'danger';
        }

        this.load = false;

      },
      error => {
        this.message = "Ha ocurrio un error"
        this.status = 'danger';
        this.load = false;
        console.log(<any>error);
      }
    );
  }

  changeNav(e) {

    //Nav Anterior
    let old_target_nav = document.getElementsByClassName("nav-link active");
    
    if (old_target_nav.length > 0) {
      let i;
      for (i = 0; i < old_target_nav.length; i++) {
        old_target_nav[i].classList.remove('active');
      }
    }

    //Formulario Anterior
    let old_target_form = document.getElementsByClassName("tab-pane active");

    if (old_target_form.length > 0) {
      let j;
      for (j = 0; j < old_target_form.length; j++) {
        old_target_form[j].classList.remove('active');
      }
    }

    //Nav Clickeada
    let target_nav = e.target;
    target_nav.classList.add('active');
    
    //Formulario Clickeado
    let target_form = document.getElementById(target_nav.getAttribute('aria-controls'));
    target_form.classList.add('active');
  }

  nextNav(e){
    let event = e.target;

    this.tab = event.attributes.id.value;

    if(this.tab === "next-1"){

      let last_element = document.querySelector("#nav-personal-tab");
      let last_panel = document.querySelector("#nav-personal");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-laboral-tab");
      let next_panel = document.querySelector("#nav-laboral");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }
    
    if(this.tab === "next-2"){

      let last_element = document.querySelector("#nav-laboral-tab");
      let last_panel = document.querySelector("#nav-laboral");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-financiera-tab");
      let next_panel = document.querySelector("#nav-financiera");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }
    
    if(this.tab === "next-3"){

      let last_element = document.querySelector("#nav-financiera-tab");
      let last_panel = document.querySelector("#nav-financiera");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-referencias-tab");
      let next_panel = document.querySelector("#nav-referencias");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }   
    
    if(this.tab === "next-4"){
      let last_element = document.querySelector("#nav-referencias-tab");
      let last_panel = document.querySelector("#nav-referencias");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-cuenta-tab");
      let next_panel = document.querySelector("#nav-cuenta");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }   

  }

  validarTiempo(e){
    this.actividad_economica.tiempo_empresa = e.value;
    console.log('por ak');
    var tiempo_valido = false;
    if (this.actividad_economica.tiempo_empresa != "6 meses") {
      tiempo_valido = true;
    }
    return tiempo_valido;
  }
}