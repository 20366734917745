export class PersonaDatosSolicitud {
    
    constructor(
        public expedicion_documento: Date,
        public ciudad_nacimiento: number,
        public pais_nacimiento: string,
        /* public nacionalidad: string, */
        public sexo: string,
        public estado_civil: number,
        public personas_a_cargo: number,
        public nivel_academico: number,
        public banco: number,
        public tipo_cuenta: number,
        public numero_cuenta: number,
        public profesion: string,
        /* public declara_renta: boolean, */
        public direccion_residencia: string,
        public barrio: string,
        public ciudad_residencia: number,
        public telefono_residencia: string,
        public expedida: number,
        public convenio: string,
        public nombre_empresa_sin_convenio: string,
        public nombre_empresa_asalariado_cars: string,
        public origen_ingreso: string,
        public ocupacion: number,
        public tiempo_empresa: string

    ) {}

}