import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as jsPDF from "jspdf";

import { Global } from '../../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {

  private solicitud;
  public kushki_response;
  public mensaje_kushki;
  public valor_formateado;
  public fecha_creacion;
  public estado_transaccion;
  public data;
  public status;
  public company_info: string;

  @ViewChild('content', {static: false}) content: ElementRef

  constructor(
    private _generalService: GeneralService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.solicitud = +params['solicitud'];
    });
    
    this.getInfoComprobanteDePago();

  }

  getInfoComprobanteDePago() {

    var token = this.getToken();
    
    let json = Global.json;
    json.body.serviceID.id = '45';
    json.body.inputParameters.push({ 'name': 'token', 'value': token });

    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          let kushki = JSON.parse(response.body.outputParameters.value);
          this.kushki_response = JSON.parse(kushki.kushki);
          this.data = JSON.parse(kushki.data);

          if(this.kushki_response.processorState == 'NOT_AUTHORIZED') {
            this.mensaje_kushki = "La transacción fue RECHAZADA"
            this.estado_transaccion = "RECHAZADA";
            this.status = 'warning';
          }

          if(this.kushki_response.processorState == 'PENDING') {
            this.mensaje_kushki = "La transacción está PENDIENTE. Por favor verificar si el débito fue realizado en el Banco"
            this.estado_transaccion = "PENDIENTE";
            this.status = 'warning';
            this.company_info = "En este momento su # "+this.data.ticket_number+" presenta un proceso de pago cuya transacción se encuentra PENDIENTE de recibir confirmación por parte de su entidad financiera, por favor espere unos minutos y vuelva a consultar más tarde para verificar si su pago fue confirmado de forma exitosa. Si desea mayor información sobre el estado actual de su operación puede comunicarse a nuestras líneas de atención al cliente 301 7679218 o enviar un correo electronico a servicioalcliente@moneytech.com.co y preguntar por el estado de la transacción: "+this.kushki_response.trazabilityCode;
          }

          if(this.kushki_response.processorState == 'OK') {
            this.mensaje_kushki = "La transacción fue aprobada"
            this.estado_transaccion = "APROBADA";
            this.status = 'success';
            this.company_info = "En este momento su # "+this.data.ticket_number+" ha finalizado su proceso de pago y cuya transacción se encuentra APROBADA en su entidad financiera. Si desea mayor información sobre el estado de su operación puede comunicarse a nuestras líneas de atención al cliente 301 7679218 o enviar un correo electronico a servicioalcliente@moneytech.com.co y preguntar por el estado de la transacción: "+this.kushki_response.trazabilityCode;
          }

          if(this.kushki_response.processorState == 'FAILED') {
            this.mensaje_kushki = "Transacción FALLIDA"
            this.estado_transaccion = "FALLIDA";
            this.status = 'warning';
          }

          this.valor_formateado = new Intl.NumberFormat().format(this.kushki_response.amount.subtotalIva0);
          this.fecha_creacion = new Date(this.kushki_response.created);
          this.fecha_creacion = this.fecha_creacion.toLocaleString();
          /* console.log(this.kushki_response); */
        }
      },
      error => {
        console.log(<any>error)
      }
    );
  }

  descargarComprobante(e) {

    e.preventDefault();

    let doc = new jsPDF();

    let specialElementHandlers = {
      '#editor': function(element, renderer) {
        return true;
      }
    };

    let content = this.content.nativeElement;
    doc.fromHTML(content.innerHTML, 15, 15, {
      'width': 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save('test.pdf');

  }

  getToken() {
    let token = sessionStorage.getItem('token');
    sessionStorage.removeItem('token');

    return token;
  }

}
