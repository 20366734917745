import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent implements OnInit {

  public solicitud;
  public title: string;
  public pse: boolean;
  public efectivo: boolean;
  
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService
  ) { }

  ngOnInit() {

    this._route.params.subscribe(params => {
      this.solicitud = +params['solicitud'];
    });

    this.togglePse();

  }

  togglePse() {
    //this._generalService.solicitud$.emit(this.solicitud);
    sessionStorage.setItem('solicitud', this.solicitud);
    this.title = "Transferencia";
    this.pse = true;
    this.efectivo = false;
  }

  toggleEfectivo() {
    //this._generalService.solicitud$.emit(this.solicitud);
    sessionStorage.setItem('solicitud', this.solicitud);
    this.title = "Efectivo";
    this.pse = false;
    this.efectivo = true;
  }

}
