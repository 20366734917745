import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { Global } from '../../../../services/global';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-main-credit-request',
  templateUrl: './main-credit-request.component.html',
  styleUrls: ['./main-credit-request.component.css']
})
export class MainCreditRequestComponent implements OnInit {

  public simulator:boolean = true;
  public solicitud:boolean = false;
  /* public title: string = "Simulador"; */
  public title: string = "Formulario solicitud de crédito";
  public identity;
  public crear_solicitud;
  public tipo;

  constructor(
    private _generalService: GeneralService,
    private _route: ActivatedRoute,
    private _router: Router,
    ) {
      this.identity = this._generalService.getIdentity();
      this.crear_solicitud = true;
    }

  ngOnInit() {
    this.validarNumeroSolicitudes();
  }

  toggleFormulario(e) {
    this.title = "Formulario solicitud de crédito";
    this.solicitud = true;
    this.simulator = false;
  }

  toggleSimulador(e) {
    this.title = "Simulador";
    this.solicitud = false;
    this.simulator = true;
  }

  validarNumeroSolicitudes() {
    let json = Global.json;

    json.body.serviceID.id = '39';
    let personID = this.identity.id;
    let inputParameters = {"name": "id", "value":personID};

    json.body.inputParameters.push(inputParameters);

    this._generalService.api(json).subscribe(
      response => {
        if(response) {
          let respuesta = JSON.parse(response.body.outputParameters.value);
          this.crear_solicitud = respuesta;

          if(!this.crear_solicitud) {
            this.title = "Atención";
            let obj_mensaje = {'mensaje': 'Ya se encuentra en proceso el número maximo de solicitudes el cual se tiene permitido', 'alert': 'warning'}
            sessionStorage.setItem('mensaje', JSON.stringify(obj_mensaje))
          } else {
            this.crear_solicitud = true;
          }
        }
      },
      error => {
        console.log(<any>error);
      }
    )
  }

  getTipoSimulador() {

    this._route.params.subscribe(params => {
      this.tipo = params['tipo'];    
    });
  }

}
