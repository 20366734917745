import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { GeneralService } from 'src/app/services/general.service';

@Injectable({
	providedIn: 'root'
})
export class UserActivation implements CanActivate {


	constructor(
		private _router: Router,
		private _generalService: GeneralService
	) {}

	canActivate() {
		let identity = this._generalService.getIdentity();
		
		if(identity) {
			if(!identity.estado) {
				return true;
			} else {
				this._router.navigate(['/main/user/']);
				return false;
			}
		} else {
			return true;
		}
	}

}