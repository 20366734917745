import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GenerarPreguntas } from 'src/app/models/GenerarPreguntas';
import { PreguntaRespuesta } from 'src/app/models/PreguntaRespuesta';
import { Subscription } from 'rxjs';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

    public tests: string;
    public pregunta_respuesta: Array<any> = [];
    public preguntas_respuestas: Array<any> = [];
    public PreguntasRespuesta: PreguntaRespuesta;
    public message: string;
    public status: string;
    public load: boolean;
    public load_questions: boolean;
    public sent: boolean;
    public checked: boolean;
    public count_checked_radio: number
    public preguntas;
    public generated: boolean;
    public regValidacion;
/*    public preguntas = {
     "@attributes": {
        "id": "59213930",
        "resultado": "01",
        "registro": "170024444",
        "excluirCliente": "false",
        "alertas": "false",
        "respuestaAlerta": "03",
        "codigoAlerta": "00"
    },
    "Pregunta": [
        {
            "@attributes": {
                "id": "005001004",
                "texto": "EN LOS ULTIMOS 2 AÑOS (ENTRE MARZO DE 2018 Y FEBRERO DE 2020) SU ULTIMO(A) PLAN / PAQUETE TELECOMUNICACIONES  CON DIRECTV COLOMBIA LTDA FUE CERRADO(A) / CANCELADO ENTRE:",
                "orden": "1",
                "idRespuestaCorrecta": "00",
                "peso": "2"
            },
            "Respuesta": [
                {
                    "@attributes": {
                        "id": "001",
                        "texto": "ENTRE JULIO Y SEPTIEMBRE DE 2018"
                    }
                },
                {
                    "@attributes": {
                        "id": "002",
                        "texto": "ENTRE ENERO Y MARZO DE 2018"
                    }
                },
                {
                    "@attributes": {
                        "id": "003",
                        "texto": "ENTRE ENERO Y MARZO DE 2019"
                    }
                },
                {
                    "@attributes": {
                        "id": "004",
                        "texto": "ENTRE JULIO Y SEPTIEMBRE DE 2019"
                    }
                },
                {
                    "@attributes": {
                        "id": "005",
                        "texto": "NUNCA HE TENIDO PLAN / PAQUETE TELECOMUNICACIONES CON LA ENTIDAD"
                    }
                }
            ]
        },
        {
            "@attributes": {
                "id": "005001002",
                "texto": "EN FEBRERO DE 2020 SU CREDITO DE VEHICULOS / MOTOS  CON AUTOCREDITO S.A.S.:",
                "orden": "2",
                "idRespuestaCorrecta": "00",
                "peso": "2"
            },
            "Respuesta": [
                {
                    "@attributes": {
                        "id": "001",
                        "texto": "ESTABA ABIERTO/VIGENTE"
                    }
                },
                {
                    "@attributes": {
                        "id": "002",
                        "texto": "ESTABA CANCELADA/SALDADA/CERRADA/INACTIVA"
                    }
                },
                {
                    "@attributes": {
                        "id": "003",
                        "texto": "NUNCA HE TENIDO CREDITO DE VEHICULOS / MOTOS CON LA ENTIDAD"
                    }
                }
            ]
        },
        {
            "@attributes": {
                "id": "005003003",
                "texto": "CON CUAL DE LAS SIGUIENTES ENTIDADES USTED TIENE O HA TENIDO EN LOS ULTIMOS 5 AÑOS (ENTRE MARZO DE 2015 Y FEBRERO DE 2020) UN/UNA CREDITO DE VEHICULOS O FACTORING  ?",
                "orden": "3",
                "idRespuestaCorrecta": "00",
                "peso": "3"
            },
            "Respuesta": [
                {
                    "@attributes": {
                        "id": "001",
                        "texto": "BANCOLOMBIA S.A."
                    }
                },
                {
                    "@attributes": {
                        "id": "002",
                        "texto": "DANN REGIONAL COMPAÑIA DE FINANCIAMIENTO S.A."
                    }
                },
                {
                    "@attributes": {
                        "id": "003",
                        "texto": "BANCO SERFINANZA S.A"
                    }
                },
                {
                    "@attributes": {
                        "id": "004",
                        "texto": "BANCO DE OCCIDENTE"
                    }
                },
                {
                    "@attributes": {
                        "id": "005",
                        "texto": "COLTEFINANCIERA S. A. COMPAÑIA DE FINANCIAMIENTO"
                    }
                },
                {
                    "@attributes": {
                        "id": "006",
                        "texto": "NINGUNA DE LAS ANTERIORES"
                    }
                }
            ]
        },
        {
            "@attributes": {
                "id": "005001003",
                "texto": "EN LOS ULTIMOS 2 AÑOS (ENTRE MARZO DE 2018 Y FEBRERO DE 2020) SU ULTIMO(A) TARJETA DE CREDITO VISA CON BANCO DE OCCIDENTE FUE CERRADO(A) / CANCELADO ENTRE:",
                "orden": "4",
                "idRespuestaCorrecta": "00",
                "peso": "3"
            },
            "Respuesta": [
                {
                    "@attributes": {
                        "id": "001",
                        "texto": "ENTRE JULIO Y SEPTIEMBRE DE 2018"
                    }
                },
                {
                    "@attributes": {
                        "id": "002",
                        "texto": "ENTRE ENERO Y MARZO DE 2019"
                    }
                },
                {
                    "@attributes": {
                        "id": "003",
                        "texto": "ENTRE ENERO Y MARZO DE 2018"
                    }
                },
                {
                    "@attributes": {
                        "id": "004",
                        "texto": "ENTRE JULIO Y SEPTIEMBRE DE 2019"
                    }
                },
                {
                    "@attributes": {
                        "id": "005",
                        "texto": "NUNCA HE TENIDO TARJETA DE CREDITO CON LA ENTIDAD"
                    }
                }
            ]
        }
    ],
    "status": "success"
    } */

    public identity;
    public generar_preguntas: GenerarPreguntas;

    public momento: string;
    public solicitud: number;

    public momentoSuscription: Subscription;
    public solicitudSuscription: Subscription;
    
    constructor(
        private _generalService: GeneralService,
        private _route: ActivatedRoute,
        private _router: Router
    ) { 

        this.regValidacion = this.getReValidacion();
        this.identity = this._generalService.getIdentity();
        this.load = false;
        this.load_questions = false;
        this.checked = false;
        this.generated = false;
        this.count_checked_radio = 0;

        this.generar_preguntas = new GenerarPreguntas(
        this.identity.identificacion,
        this.regValidacion,
        "electroventas",
        "expco_evidente_master",
    );

    this.solicitud = null;

  }

  ngOnInit() {
      var solicitud = sessionStorage.getItem('id_solcitud');
      this.solicitud = parseInt(solicitud);      
      console.log('ojo=>'+this.solicitud);
    if(isNaN(this.solicitud)){
        this.load_questions = false;
        this.status = 'danger';
        this.message =  "Ha ocurrido un error No se envio el id de la solicitud";
        console.log('fallo');
        throw new Error(this.message);
    }else{
        this.generarPreguntas();
    }

  }

  generarPreguntas() {

    let json = this._generalService.dataJson(this.generar_preguntas, '58');
    //console.log(JSON.stringify(json));
    this.load_questions = true;

  	this._generalService.api(json).subscribe(

  		response => {

        if(response) {
          if(response.body) {
            let response_preguntas = JSON.parse(response.body.outputParameters.value);
            //response_preguntas = JSON.parse(response_preguntas);
            console.log(response_preguntas)
            if(response_preguntas.resultado == '01') {
                console.log('entra');
                this.generated = true;
                this.preguntas = (response_preguntas);
                
            }/* else if(response_preguntas['@attributes']['resultado'] == '11') {
                this.status = 'warning';
                this.message =  "Se han realizado el número maximo de intentos";
            } */else {
                this.status = 'warning';
                //this.message =  "No se han podido generar las preguntas, por favor intentalo nuevamente generando un nuevo código OTP";
                this.message =  this.getReGeCuestionario(response_preguntas.resultado);
            }

          } else {
                this.status = 'warning';
                this.message =  "Ha ocurrido un error al generar preguntas. Contacta con soporte";
            } 
        } 
        this.load_questions = false;
      }, error => {
        this.load_questions = false;
            this.status = 'danger';
            this.message =  "Ha ocurrido un error al generar preguntas. Contacta con soporte";
            console.log(<any>error);
      }
    );

  }

	getReValidacion() {

		let regValidacion;

		this._route.params.subscribe(params => {

  			regValidacion = params['regValidacion'];
  		
  		});

  		return regValidacion;
    }
    
    onSubmit() {
        this.load = true;
        //this.sent = true;

        /*this.solicitudSuscription = this._generalService.solicitud$.subscribe(solicitud => {
            this.solicitud = solicitud;
          });
          */
          //this.getStorageData();

        console.log('sol=>'+this.solicitud);

        this.PreguntasRespuesta = new PreguntaRespuesta(
            this.pregunta_respuesta,
            this.preguntas.registro,
            this.identity.identificacion,
            this.preguntas.id,
            this.identity.email,
            JSON.stringify(this.preguntas_respuestas),
            this.momento,
            this.solicitud,
            this.identity.persona,
            this.regValidacion
        );

        let json = this._generalService.dataJson(this.PreguntasRespuesta, '38');
        console.log(json);
        this._generalService.api(json).subscribe(
  
        response => {
            console.log(response);
            if(response) {
        
                if(response.body) {
                    let response_preguntas = JSON.parse(response.body.outputParameters.value);
                    console.log(response_preguntas);
                    if(response_preguntas.aprobacion == "true") {

                        this.message = "El usuario ha sido verificado";
                        this.status = 'success';
                        this.identity.estado = true;

                        sessionStorage.setItem('identity', JSON.stringify(this.identity));

                    } else {
                        this.status = 'warning';
                        this.message =  "El usuario no ha podido ser verificado, intentalo mas tarde";
                    }
                }

            } else {
                this.status = 'warning';
                this.message =  "Ha ocurrido un error al validar el usuario, contacta con soporte";
            }

          this.load = false;
        }, error => {
            this.status = 'danger';
            this.message =  "Ha ocurrido un error al validar el usuario, contacta con soporte";
            console.log(<any>error);
            this.load = false;
        }
      );

    }

    onChange(e) {

        this.count_checked_radio++;

        let id_explode = e.target.id.split('-');

        //console.log(e.target);
        
        let orden = id_explode[1];
        let respuesta = id_explode[2];

        if(this.pregunta_respuesta != undefined && this.pregunta_respuesta.length > 0) {
            this.pregunta_respuesta.forEach((pregunta_respuesta, index) => {
                if(pregunta_respuesta.idPregunta != orden) {

                    let existe = false;

                    this.pregunta_respuesta.forEach((pregunta_respuesta, index) => {
                        if(pregunta_respuesta.idPregunta == orden) {
                            existe = true;
                        }
                    });

                    if(!existe) {
                        this.pregunta_respuesta.push({'idPregunta': id_explode[1], 'idRespuesta': id_explode[2]});
                        this.preguntas_respuestas.push({'pregunta': e.target.parentNode.parentNode.textContent, 'respuesta': e.target.parentNode.textContent});
                    }
                    
                } else {
                    this.pregunta_respuesta[index] = {'idPregunta': id_explode[1], 'idRespuesta': id_explode[2]};
                    this.preguntas_respuestas[index] = {'pregunta': e.target.parentNode.parentNode.textContent, 'respuesta': e.target.parentNode.textContent};
                } 
            });
        } else {
            this.pregunta_respuesta.push({'idPregunta': id_explode[1], 'idRespuesta': id_explode[2]});
            this.preguntas_respuestas.push({'pregunta': e.target.parentNode.parentNode.textContent, 'respuesta': e.target.parentNode.textContent});
        }

        if(this.count_checked_radio == this.preguntas.Pregunta.length) {
            this.checked = true;
        }
    
    }

    getStorageData() {

        //this.momento = sessionStorage.getItem('momento');
        //console.log(this.momento);
        var solicitud = sessionStorage.getItem('solicitud');
        this.solicitud = null;
        if(solicitud) {
            this.solicitud = parseInt(solicitud);        

            sessionStorage.removeItem('solicitud');
        }

        //sessionStorage.removeItem('momento')

    }

    getReGeCuestionario(codigo){
        const resp = [];
        resp["00"] = "Usuario no tiene activa la opción de última consulta ó no hubo resultados en la consulta";
        resp["01"] = "Preguntas generadas con éxito";
        resp["02"] = "Error en el proceso de generación de preguntas ó cuestionario ya generado para ese número de validación.";
        resp["07"] = "No hay suficientes preguntas.";
        resp["10"] = "Máximo de intentos por día";
        resp["11"] = "Máximo de intentos por mes";
        resp["12"] = "Máximo de intentos por año";
        resp["13"] = "Excedió el número de ingresos permitidos para el producto por este día";
        resp["14"] = "Excedió el número de ingresos permitidos para el producto por este mes";
        resp["15"] = "Excedió el número de ingresos permitidos para el producto por este año";
        resp["17"] = "Consulta no autorizada - t1";
        resp["18"] = "Consulta no autorizada – t2";
        resp["19"] = "Consulta no autorizada – t3";
        resp["21"] = "No se han podido generar las preguntas, por favor intentalo nuevamente generando un nuevo código OTP";

        return resp[codigo];
    }

}
