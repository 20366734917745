import { Component, OnInit } from '@angular/core';
import { Kushki } from '@kushki/js';

import { Global } from '../../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-cajita-card',
  templateUrl: './cajita-card.component.html',
  styleUrls: ['./cajita-card.component.css']
})
export class CajitaCardComponent implements OnInit {

  private kushki: Kushki;
  public token;

  constructor(
  	private _generalService: GeneralService,
  ) { 

    this.kushki = new Kushki({
      merchantId: '23fcc7f0c19343bf91802e50937165c2',
      inTestEnvironment: true,
    });

  }

   ngOnInit() {

    this.initKushki();

  } 

  initKushki() {
   
    var callback = this.callbackResponse.bind(this);
    
    this.kushki.requestTransferToken({
      bankId: '1022', // Only required for Colombian merchants
      callbackUrl: 'localhost:4200', // Not required for Mexico
      userType: '1',
      documentType: 'NIT',
      documentNumber: '171054019',
      paymentDesc: 'Description of the payment.',
      email: 'kushki@kushki.com',
      currency: 'COP',
      amount: { 
        subtotalIva: 100000,
        subtotalIva0: 0,
        iva: 10000,
        extraTaxes: {
            propina: 0,
            tasaAeroportuaria: 0,
            agenciaDeViajes: 0,
            iac: 0
            }
      }
    },
    callback); // Also you can set the function directly

  }

  callbackResponse(response) {

    if(!response.code){

      let json = Global.json;
      json.body.serviceID.id = '40';
      json.body.inputParameters.push({'name': 'token', 'value': response.token});

      this._generalService.api(json).subscribe(

        response => {
          console.log(JSON.parse(response));
        }, error => {
          console.log(<any>error);
        }
      );

    } else {
      console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
    }

  }

}



/*
  * Cual son los servicios que se deben consultar (PAGAR EN EFECTIVO, PSE -> TRANSFERENCIA BANCARIA) PSE -> TRANSFERENCIA | EFECTIVO -> CASH IN
  * Como integrar la cajita al FRONT-END
  * Se puede integrar la cajita en FRONT con el api en BACK?
  * 
  * 
  * patch.js
*/


