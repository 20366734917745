import { Component, OnInit, OnDestroy } from '@angular/core';

import { Global } from '../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';
import { PersonaDatosPersonalCuenta } from 'src/app/models/PersonaDatosPersonalCuenta';
import { PersonaDatosSolicitud } from 'src/app/models/PersonaDatosSolicitud';
import { Conyuge } from 'src/app/models/Conyugue';
import { InformacionLaboral } from 'src/app/models/InformacionLaboral';
import { Ingresos } from 'src/app/models/Ingresos';
import { Egresos } from 'src/app/models/Egresos';
import { ReferenciasPersonales } from 'src/app/models/ReferenciasPersonales';
import { ReferenciasFamiliares } from 'src/app/models/ReferenciasFamiliares';
import { Solicitud } from 'src/app/models/solicitud';
import { ActividadEconomica } from 'src/app/models/ActividadEconimica';
import { Carro } from 'src/app/models/Carro';
import { Subscription } from 'rxjs';
import { isNull } from 'util';

@Component({
  selector: 'app-credit-request',
  templateUrl: './credit-request.component.html',
  styleUrls: ['./credit-request.component.css']
})
export class CreditRequestComponent implements OnInit, OnDestroy {

  public identity;
  public solicitud_simulador;
  public respuesta_solicitud;
  public status;
  public load: boolean;
  public mostrarCardConyuge: boolean;
  public mostrarCardCarro: boolean;
  public jumping: boolean;
  public jumps: number;
  public card: number;
  public message;
  public ocupacion;

  public persona: PersonaDatosPersonalCuenta;
  public datos_solicitud: PersonaDatosSolicitud;
  /* public conyuge: Conyuge; */
  /* public info_laboral: InformacionLaboral */;
  public ingresos: Ingresos;
  public egresos: Egresos;
  public referencias_familiares: ReferenciasFamiliares;
  public referencias: ReferenciasPersonales;
  public solicitud: Solicitud;
  public actividad_economica: ActividadEconomica;
  public carro: Carro;

  public bancos;
  public ciudades;
  public estados_civil;
  public cuentas_banco;
  public niveles_academico;
  public tipos_identificacion;
  public convenios;
  public tipos_contrato;
  public ocupaciones;
  public convenios_dinamico: Array<any> = [];

  public checkNum: number;
  public checked: boolean;
  
  public tipoSolicitudSubscribe: Subscription;
  public tipo_solicitud;

  public nombre_referencias_personales: string;
  public telefono_referencias_personales: string;
  public tab: string;

  constructor(
    private _generalService: GeneralService
  ) {

    this.identity = this._generalService.getIdentity();
    this.mostrarCardConyuge = false;
    this.jumping = true;
    this.jumps = 0;
    this.card = 1;
    this.ocupacion = null;

    this.persona = new PersonaDatosPersonalCuenta(
      this.identity.tipo_identificacion_id,
      this.identity.identificacion,
      this.identity.primer_nombre,
      this.identity.primer_apellido,
      this.identity.segundo_nombre,
      this.identity.segundo_apellido,
      this.identity.email,
      this.identity.fecha_de_nacimiento,
      this.identity.fecha_de_expedicion,
      this.identity.celular,
      this.identity.persona
    );

    //console.log(this.persona);
    this.referencias = new ReferenciasPersonales(
      null,
      null,
      null,
      null
    );

    /* this.solicitud = new Solicitud(
      monto,
      this.solicitud_simulador.solicitud.persona,
      fecha_primer_pago,
      this.solicitud_simulador.solicitud.producto,
      this.solicitud_simulador.solicitud.destino,
      this.solicitud_simulador.solicitud.frecuencia_de_pago,
      this.solicitud_simulador.solicitud.numero_de_cuotas,
      this.solicitud_simulador.solicitud.tasa_de_interes,
      fecha_de_desembolso,
      this.solicitud_simulador.solicitud.convenio
    ); */

    this.datos_solicitud = new PersonaDatosSolicitud(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      '',
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    /* this.datos_solicitud.nombre_empresa_sin_convenio = true; */
    /* console.log(this.datos_solicitud.nombre_empresa_sin_convenio); */

    /* this.conyuge = new Conyuge(
      null,
      null,
      null,
      null,
      '',
      '',
      null,
      null,
      null
    ); */

    this.actividad_economica = new ActividadEconomica(
      null,
      null,
      null,
      null,
      null,
      null,
      
    );

    this.ingresos = new Ingresos(
      null,
      null,
      null
    );

    this.egresos = new Egresos(
      null,
      null,
    );

    this.referencias_familiares = new ReferenciasFamiliares(
      null,
      null,
      null,
      null,
      null
    );

    /* this.referencias_personales = new ReferenciasPersonales(
      null,
      null,
      null,
      null
    ); */

    this.carro = new Carro(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0
    );

  }


  ngOnInit() {

    //this.getLocalStorageSolicitud();
    this.getSolicitudData();
    this.getBancos();
    this.getCiudades();
    this.getEstadosCivil();
    this.getTiposCuentaBanco();
    this.getNivelesAcedemico();
    this.getTiposIdentificacion();
    this.getConvenios();
    this.getTiposContrato();
    this.getOcupaciones();
    

    this.tipoSolicitudSubscribe = this._generalService.tipo_solicitud$.subscribe(tipo_solicitud => {
      this.tipo_solicitud = tipo_solicitud;
    });
    this.persona;
    console.log(this.persona);
  }

  ngOnDestroy() {
    this.tipoSolicitudSubscribe.unsubscribe();
  }

  onSubmit() {
    this.getLocalStorageSolicitud();

    this.load = true;

    var btn_submit = document.getElementById('btn-submit');
    btn_submit.setAttribute('disabled', 'true');

    this.referencias = new ReferenciasPersonales(
      this.nombre_referencias_personales,
      null,
      this.telefono_referencias_personales,
      null
    );

    /* console.log(this.referencias); */

    this.datos_solicitud.ocupacion = this.ocupacion;    

    let json = this._generalService.dataJson([
      this.persona,
      this.datos_solicitud,
      /* this.conyuge, */
      this.actividad_economica,
      this.ingresos,
      this.egresos,
      this.referencias_familiares,
      this.referencias,
      this.solicitud,
      this.carro
    ], "34");

    /* console.log(json) */

    this._generalService.api(json).subscribe(
      response => {

        if (response && response.body) {
          this.respuesta_solicitud = JSON.parse(response.body.outputParameters.value);
         
          if (this.respuesta_solicitud && this.respuesta_solicitud.status == 'success') {
            this.status = 'success';
          } else if (!response.body) {
            this.status = 'warning';
          } else if(!this.respuesta_solicitud.solicitud) {
            this.status = 'warning';
            this.message = 'Ya se encuentra en proceso el número maximo de solicitudes el cual se tiene permitido '
          } else {
            this.status = 'warning';
          }
        } else {
          this.message = response.message;
          this.status = 'danger';
        }
        
        this.load = false;

      },
      error => {
        this.message = "Ha ocurrio un error"
        this.status = 'danger';
        this.load = false;
        console.log(<any>error);
      }
    );

  }

  getSolicitudData() {
    /* console.log(this.info_laboral); */
    let json = Global.json;
    json.body.serviceID.id = "43";
    json.body.inputParameters.push({ 'name': 'persona', 'value': this.identity.persona });
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          var outputParameters = JSON.parse(response.body.outputParameters.value);
          /* console.log(outputParameters); */
          if (outputParameters) {

            this.datos_solicitud = outputParameters.persona_datos_solicitud;
            /* if(outputParameters.conyuge) {
              this.conyuge = outputParameters.conyuge;
            } */
            this.actividad_economica = outputParameters.info_laboral;
            this.ingresos = outputParameters.ingresos;
            /* this.egresos = outputParameters.egresos; */
            this.referencias_familiares = outputParameters.referencias_familiares;
            this.referencias = outputParameters.referencias_personales;

            if(this.datos_solicitud.convenio == 'Otra') {
              var nombre_empresa = document.getElementById('nombre_empresa');
              var cargo = document.getElementById('cargo'); 
              cargo.className = cargo.className.replace(/(?:^|\s)col-12 col-sm-12 col-md-6 col-lg-6(?!\S)/g, 'col-12 col-sm-12 col-md-6 col-lg-3');
              
              nombre_empresa.hidden = false;

            }

            //this.validarEstadoCivil();
          }

          //console.log(this.conyuge);

        }
      },
      error => {
        console.log(<any>error)
      }
    );
  }

  getBancos() {

    let json = Global.json;
    json.body.serviceID.id = "29";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.bancos = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );
  }

  getCiudades() {

    let json = Global.json;
    json.body.serviceID.id = "5";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.ciudades = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getEstadosCivil() {

    let json = Global.json;
    json.body.serviceID.id = "30";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.estados_civil = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getTiposCuentaBanco() {

    let json = Global.json;
    json.body.serviceID.id = "31";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.cuentas_banco = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getNivelesAcedemico() {

    let json = Global.json;
    json.body.serviceID.id = "32";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.niveles_academico = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getTiposIdentificacion() {
    //console.log('entra');
    let json = Global.json;
    json.body.serviceID.id = "26";
    this._generalService.api(json).subscribe(
      response => {
        //console.log(response);
        if (response) {
          this.tipos_identificacion = JSON.parse(response.body.outputParameters.value);
          //console.log(this.tipos_identificacion);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getConvenios() {

    let json = Global.json;
    json.body.serviceID.id = "7";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.convenios = JSON.parse(response.body.outputParameters.value);
          console.log(this.convenios);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  getTiposContrato() {

    let json = Global.json;
    json.body.serviceID.id = "33";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.tipos_contrato = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  changeBackgroud(e, target = null) {
    if (target) {
      target.scrollIntoView();
    }
    target.scrollIntoView();
    let event_target = e.target;
    let parent_element = event_target.parentNode.parentNode.parentNode;

    // Se optiene el elemento con id opened para remover el backgroud
    let last_element = document.getElementById('opened');

    if (last_element.textContent != event_target.textContent) {

      last_element.parentNode.parentElement.className = last_element.parentNode.parentElement.className.replace(/(?:^|\s)bg-primary-af(?!\S)/g, ' bg-white');
      last_element.children[0].className = last_element.children[0].className.replace(/(?:^|\s)collapse-title-white(?!\S)/g, ' collapse-title-af');

    }
    // Si encuentra en el evento la clase collapsed quiere decir que esta cerrada la pestalla del wizard
    if (event_target.classList.contains('collapsed')) {
      //console.log(last_element);
      // Quita el id con valor opened el cual capturara la ventana del wizard que esta abierta
      last_element.removeAttribute('id');

      parent_element.className = parent_element.className.replace(/(?:^|\s)bg-white(?!\S)/g, ' bg-primary-af');
      event_target.className = event_target.className.replace(/(?:^|\s)collapse-title-af(?!\S)/g, ' collapse-title-white');

    } else {

      parent_element.className = parent_element.className.replace(/(?:^|\s)bg-primary-af(?!\S)/g, ' bg-white');
      event_target.className = event_target.className.replace(/(?:^|\s)collapse-title-white(?!\S)/g, ' collapse-title-af');

    }

    event_target.parentElement.setAttribute('id', 'opened');

  }

  next(e, target = null) {
    if (target) {
      target.scrollIntoView();
    }
    let event;
    let button_next;

    event = e.target;

    this.card = parseInt(event.attributes.id.value);

    /* if (this.card == 2) this.validarEstadoCivil(); */

    let sum_id = this.card + this.jumps;

    button_next = document.getElementById('c-' + sum_id + '-mb');

    let last_element = document.getElementById('opened');

    last_element.parentNode.parentElement.className = last_element.parentNode.parentElement.className.replace(/(?:^|\s)bg-primary-af(?!\S)/g, ' bg-white');
    last_element.children[0].className = last_element.children[0].className.replace(/(?:^|\s)collapse-title-white(?!\S)/g, ' collapse-title-af');

    /* if(button_next.textContent == " Información del vehículo ") {
      this.listenerTipoSolicitud();
    } */

    button_next.parentNode.parentNode.parentElement.className = button_next.parentNode.parentNode.parentElement.className.replace(/(?:^|\s)bg-white(?!\S)/g, ' bg-primary-af');
    button_next.className = button_next.className.replace(/(?:^|\s)collapse-title-af(?!\S)/g, ' collapse-title-white');

    last_element.removeAttribute('id');
    button_next.parentElement.setAttribute('id', 'opened');

    button_next.removeAttribute('disabled');

    this.jumps = 0;

  }

  validarEstadoCivil() {

    var sum_id: number;

    if (this.datos_solicitud.estado_civil == 4 || this.datos_solicitud.estado_civil == 2) {
      this.mostrarCardConyuge = true;
      this.jumps = 0;
      sum_id = this.card + this.jumps;
    } else {
      this.mostrarCardConyuge = false;
      this.jumps = 1;
      sum_id = this.card + this.jumps;
    }

    let data_target = 'c-' + sum_id + '-m';
    let a_attribite_next = document.getElementById(this.card.toString());

    if (!this.mostrarCardConyuge) {

      let conyuge_card = document.getElementById('c-2-mb');
      conyuge_card.setAttribute("disabled", "true");

      /* this.conyuge = new Conyuge(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ); */

    }

    a_attribite_next.attributes['data-target'].value = '#' + data_target;
    a_attribite_next.attributes['aria-controls'].value = data_target;

  }

  checkInputCheck(e) {

    let checks = document.getElementsByClassName('form-check-input');

    if (e.target.checked) {
      this.checkNum++;
    } else {
      this.checkNum--;
    }

    if (this.checkNum == checks.length) {
      this.checked = true;
    } else {
      this.checked = false;
    }

  }

  getLocalStorageSolicitud() {
    console.log('entra');
    this.solicitud_simulador = JSON.parse(sessionStorage.getItem('solicitud'));

    let monto_toString = this.solicitud_simulador.solicitud.monto_solicitado.toString();
    let monto = monto_toString.replace(/\./g, '');
    monto = parseInt(monto);
    console.log(monto);
    let fecha_primer_pago = this._generalService.dateFormated(this.solicitud_simulador.solicitud.fecha_primer_pago);
    let fecha_de_desembolso = this._generalService.dateFormated(this.solicitud_simulador.solicitud.fecha_de_desembolso);

    this.solicitud = new Solicitud(
      monto,
      null,
      fecha_primer_pago,
      this.solicitud_simulador.solicitud.producto,
      this.solicitud_simulador.solicitud.destino,
      this.solicitud_simulador.solicitud.frecuencia_de_pago,
      this.solicitud_simulador.solicitud.numero_de_cuotas,
      this.solicitud_simulador.solicitud.tasa_de_interes,
      fecha_de_desembolso,
      this.datos_solicitud.convenio
    );
    console.log(this.solicitud);
  }

  validateCompany(e) {

    var target = e.target;
    var nombre_empresa = document.getElementById('nombre_empresa');
    var radio_no_empresa = <HTMLInputElement>document.getElementById('radio_no_empresa');
    
    if(radio_no_empresa.checked) {
      radio_no_empresa.checked = false;
    }
    //var input_nombre_empresa = <HTMLInputElement>nombre_empresa.firstElementChild.firstElementChild.lastElementChild;
    //console.log(nombre_empresa.firstElementChild.firstElementChild.lastElementChild);

    var cargo = document.getElementById('cargo'); 
    // Si el id es "sin convenio"
    if(target.value == 113) {

      cargo.className = cargo.className.replace(/(?:^|\s)col-12 col-sm-12 col-md-6 col-lg-6(?!\S)/g, 'col-12 col-sm-12 col-md-6 col-lg-3');
      
      this.datos_solicitud.nombre_empresa_sin_convenio = null;
    
      nombre_empresa.hidden = false;

    } else {
      cargo.className = cargo.className.replace(/(?:^|\s)col-12 col-sm-12 col-md-6 col-lg-3(?!\S)/g, 'col-12 col-sm-12 col-md-6 col-lg-6');
      
      this.datos_solicitud.nombre_empresa_sin_convenio = 'null';
      nombre_empresa.hidden = true;
    }

    //console.log(this.datos_solicitud.nombre_empresa_sin_convenio);

  }

  /* listenerTipoSolicitud() {

    console.log("entre");
    this.tipoSolicitudSubscribe = this._generalService.tipo_solicitud$.subscribe(tipo_solicitud => {
      this.tipo_solicitud = tipo_solicitud;
    });
    
    console.log(this.tipo_solicitud);

  } */

  /* validarCarro() {
    console.log('entra a validar carro');
    var sum_id: number;

    if (this.carro.validar == 1) {
      console.log('ok para carro');
      this.mostrarCardCarro = true;
      this.jumps = 0;
      sum_id = this.card + this.jumps;
    } else {
      console.log('no carro');
      this.mostrarCardCarro = false;
      this.jumps = 1;
      sum_id = this.card + this.jumps;
    }

    let data_target = 'c-' + sum_id + '-m';
    //let a_attribite_next = document.getElementById(this.card.toString());

    if (!this.mostrarCardCarro) {
      let conyuge_card = document.getElementById('c-4-mb');
      conyuge_card.setAttribute("disabled", "true");
    }

    //a_attribite_next.attributes['data-target'].value = '#' + data_target;
    //a_attribite_next.attributes['aria-controls'].value = data_target;

  } */

  validateOcupacion(e) {
    console.log(e);
  }

  getOcupaciones() {

    let json = Global.json;
    json.body.serviceID.id = "54";
    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.ocupaciones = JSON.parse(response.body.outputParameters.value);
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  checkNoExisteMiEmpresa() {

    var nombre_empresa = document.getElementById('nombre_empresa');
    var cargo = document.getElementById('cargo'); 

    cargo.className = cargo.className.replace(/(?:^|\s)col-12 col-sm-12 col-md-6 col-lg-6(?!\S)/g, 'col-12 col-sm-12 col-md-6 col-lg-3');
    let input_busqueda = document.getElementById('busqueda_convenio') as HTMLInputElement;
    input_busqueda.value = 'Otra';
    this.datos_solicitud.convenio = 'Otra';

    this.datos_solicitud.nombre_empresa_sin_convenio = null;
  
    nombre_empresa.hidden = false;

  }

  buscarConvenio( buscar ){

    if(buscar.length > 3) {
      let json = Global.json;
      json.body.serviceID.id = "55";
      json.body.inputParameters.push({ 'name': 'buscar', 'value': buscar });
      this._generalService.api(json).subscribe(
        response => {
          let value = JSON.parse(response.body.outputParameters.value);
          this.convenios_dinamico = value.convenios
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  setItem( id_convenio, value ) {

    let input_busqueda = document.getElementById('busqueda_convenio') as HTMLInputElement;

    if( id_convenio === 113 ) {
      /* console.log(this.datos_solicitud.nombre_empresa_sin_convenio); */
      this.checkNoExisteMiEmpresa();
    } else {
      /* console.log(this.datos_solicitud.nombre_empresa_sin_convenio); */
      input_busqueda.value = value;
      this.datos_solicitud.convenio = id_convenio;

      var nombre_empresa = document.getElementById('nombre_empresa');
      nombre_empresa.hidden = true;

      var radio_no_empresa = document.getElementById('radio_no_empresa') as HTMLInputElement;
      radio_no_empresa.checked = false;

    }

    let lista_id = document.getElementById('lista_id');

    if( !isNull(lista_id) ) {
      lista_id.style.display = 'none';
    }

  }

  changeNav(e) {

    //Nav Anterior
    let old_target_nav = document.getElementsByClassName("nav-link active");
    
    if (old_target_nav.length > 0) {
      let i;
      for (i = 0; i < old_target_nav.length; i++) {
        old_target_nav[i].classList.remove('active');
      }
    }

    //Formulario Anterior
    let old_target_form = document.getElementsByClassName("tab-pane active");

    if (old_target_form.length > 0) {
      let j;
      for (j = 0; j < old_target_form.length; j++) {
        old_target_form[j].classList.remove('active');
      }
    }

    //Nav Clickeada
    let target_nav = e.target;
    target_nav.classList.add('active');
    
    //Formulario Clickeado
    let target_form = document.getElementById(target_nav.getAttribute('aria-controls'));
    target_form.classList.add('active');
  }

  nextNav(e){
    let event = e.target;

    this.tab = event.attributes.id.value;

    if(this.tab === "next-1"){

      let last_element = document.querySelector("#nav-personal-tab");
      let last_panel = document.querySelector("#nav-personal");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-laboral-tab");
      let next_panel = document.querySelector("#nav-laboral");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }
    
    if(this.tab === "next-2"){

      let last_element = document.querySelector("#nav-laboral-tab");
      let last_panel = document.querySelector("#nav-laboral");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-financiera-tab");
      let next_panel = document.querySelector("#nav-financiera");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }
    
    if(this.tab === "next-3"){

      let last_element = document.querySelector("#nav-financiera-tab");
      let last_panel = document.querySelector("#nav-financiera");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-referencias-tab");
      let next_panel = document.querySelector("#nav-referencias");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }   
    
    if(this.tab === "next-4"){
      let last_element = document.querySelector("#nav-referencias-tab");
      let last_panel = document.querySelector("#nav-referencias");
      last_panel.classList.remove('active');

      last_element.classList.remove('active');
      last_element.classList.add('isDisabled');

      let next_element = document.querySelector("#nav-cuenta-tab");
      let next_panel = document.querySelector("#nav-cuenta");
      next_panel.classList.add("active");
      next_element.classList.remove("isDisabled");
      next_element.classList.add("active");
    }   

  }


}
