import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from 'src/app/modules/home/components/error/error.component';
import { LoginComponent } from './components/login/login.component';
import { WizardFormComponent } from './components/wizard-form/wizard-form.component';
import { SimulatorComponent } from './components/simulator/simulator.component';
import { AboutComponent } from './components/about/about.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { WizardGuard } from '../../guards/wizard.guard';
import { UserActivation } from 'src/app/guards/userActivation.guard';
import { SimulatorCarComponent } from './components/simulator-car/simulator-car.component';
import { MainSimulatorComponent } from './components/main-simulator/main-simulator.component';

const userRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [UserActivation],
        children: [
            { path: '', component: MainSimulatorComponent },
            { path: 'home', component: WizardFormComponent },
            { path: 'wizard-form', component: WizardFormComponent/* , canActivate: [WizardGuard] */ },
            { path: 'simulator', component: SimulatorComponent },
            { path: 'simulator-car', component: SimulatorCarComponent},
            { path: 'about', component: AboutComponent },
            { path: 'forget-password', component: ForgetPasswordComponent },
            { path: 'recover-password/:token', component: RecoverPasswordComponent },
            { path: 'simulator-main', component: MainSimulatorComponent },
        ]
    },
    { path: 'login', component: LoginComponent },
    //{ path: '**', component: ErrorComponent }
];


@NgModule({
    imports: [RouterModule.forRoot(userRoutes, { useHash: true })],
    providers: [WizardGuard],
    exports: [RouterModule]
})
export class HomeRoutingModule { }