import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { signatureRoutingModule } from './signature-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { SignatureComponent } from './components/signature/signature.component';

@NgModule({
	declarations: [
		SignatureComponent
	],
	imports: [
	  CommonModule,
	  HttpClientModule,
	  FormsModule,
	  signatureRoutingModule
	],
	exports: [
	],
	providers: []
})
export class SignatureModule { }