import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Login } from '../../../../models/login';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [GeneralService]
})
export class LoginComponent implements OnInit {

  //@ViewChild("password", null) passwordElement: ElementRef;
  @ViewChild("passwordIcon", null) passwordIcon: ElementRef;

  public isActive = true;
  public login: Login;
  public message;
  public identity;

  constructor(
    private renderer: Renderer2,
    private _router: Router,
    private _route: ActivatedRoute,
    private _generalService: GeneralService
  ) { 
    this.login = new Login(null, null);
  }

  ngOnInit() {
    this.logout();
  }

  pssShowHide(input: any): any {
    
    if(input.type == 'password') {
      input.type = 'text';
      this.isActive = false;
    } else {
      input.type = 'password';
      this.isActive = true;
    }
  }

  onSubmit(form) {

    let json = this._generalService.dataJson(this.login, "2");

    this._generalService.api(json).subscribe(
      response => {
      
        if (response.body.outputParameters.value) {

          this.identity = response.body.outputParameters.value;
          sessionStorage.setItem('identity', this.identity);

          this._router.navigate(['main/user/credits']);
        } else {
          this.message = response.header.messages;
        }
      
      }, 
      error => {
        console.log(<any>error);
      }
    );

  }

  logout() {
    
    this._route.params.subscribe(params => {

      let logout = +params['sure'];

      if(logout == 1) {

        sessionStorage.removeItem('identity');

        this.identity = null;

        this._router.navigate(['/login']);

      }

    });

  }

}
