import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-home-user',
  templateUrl: './home-user.component.html',
  styleUrls: ['./home-user.component.css'],
  providers: [GeneralService]
})
export class HomeUserComponent implements OnInit {

  public identity;

  constructor(
    private _router: Router,
    private _generalService: GeneralService
  ) {
    this.identity = _generalService.getIdentity();
   }

  ngOnInit() {
    
  }

  redirectCreditComponent() {
    
    this.identity = this._generalService.getIdentity();
    let estado = this.identity.estado;
    
    if(estado) {
      this._router.navigate(['/main/user/main-credit-request']);
    } else {
      this._router.navigate(['/main/user/home']);
    }
  }

}




