import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { GeneralService } from '../services/general.service';

@Injectable({
	providedIn: 'root'
})
export class UserValidation implements CanActivate {


	constructor(
		private _router: Router,
		private _generalService: GeneralService
	) { }

	canActivate() {
		let identity = this._generalService.getIdentity();


		if (identity.estado) {
			return true;
		} else {
			this._router.navigate(['/main/user/home']);
			return false;
		}
	}


}