import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

//import { SimulatorComponent } from '../modules/home/components/simulator/simulator.component';

@Injectable({
	providedIn: 'root'
})
export class WizardGuard implements CanActivate {

	constructor(
		private _router: Router,
		//private _simulatorComponent: SimulatorComponent
	) {

	}

  	canActivate() {
    	
      let isCalculate = sessionStorage.getItem('isCalculated');
      
  		if(isCalculate && isCalculate != null && isCalculate != '') {
  			return true;
  		} else {
  			this._router.navigate(['/']);
  			return false;
  		}
  	}
  
}
