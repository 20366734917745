import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { userRoutingModule } from './user-routing.module';
import { HttpClientModule } from '@angular/common/http'; 

import { MainComponent } from './components/main/main.component';
import { HomeUserComponent } from './components/home-user/home-user.component';
import { CreditsComponent } from './components/credits/credits.component';
import { CreditDeclinedComponent } from './components/credit-declined/credit-declined.component';
import { PayComponent } from './components/pay/pay.component';
import { CreditComponent } from './components/credit/credit.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { GeneralService } from 'src/app/services/general.service';
import { RequestNewComponent } from './components/request-new/request-new.component';
import { CreditRequestComponent } from './components/credit-request/credit-request.component';
import { MainCreditRequestComponent } from './components/main-credit-request/main-credit-request.component';
//import { SimulatorComponent } from '../home/components/simulator/simulator.component';
import { UserActivationComponent } from './components/user-activation/user-activation.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { ErrorUserComponent } from './components/error-user/error-user.component';
import { OtpComponent } from './components/otp/otp.component';
import { CajitaCardComponent } from './components/kushki/cajita-card/cajita-card.component';
import { TransferInComponent } from './components/kushki/transfer-in/transfer-in.component';
import { CashInComponent } from './components/kushki/cash-in/cash-in.component';
import { VoucherComponent } from './components/kushki/voucher/voucher.component';
import { TransactionListComponent } from './components/transaction-list/transaction-list.component';
import { SimulatorsModule } from '../simulators.module';
//import { SimulatorCarComponent } from '../home/components/simulator-car/simulator-car.component'
/* import { ForgetPasswordComponent } from './components/forget-password/forget-password.component'; */
//import { UserComponent } from './user.component';

@NgModule({
    declarations: [
        MainComponent,
        HomeUserComponent,
        CreditsComponent,
        CreditDeclinedComponent,
        PayComponent,
        CreditComponent,
        UserEditComponent,
        RequestNewComponent,
        CreditRequestComponent,
        MainCreditRequestComponent,       
        UserActivationComponent,
        QuestionsComponent,
        ErrorUserComponent,
        OtpComponent,
        CajitaCardComponent,
        TransferInComponent,
        CashInComponent,
        VoucherComponent,
        TransactionListComponent
        /* ForgetPasswordComponent */
        //UserComponent
    ],
    imports: [
      CommonModule,
      HttpClientModule,
      FormsModule,
      userRoutingModule,
      SimulatorsModule
    ],
    exports: [
        MainComponent,
        HomeUserComponent
    ],
    providers: [GeneralService]
  })
  export class UserModule { }