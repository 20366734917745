import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Solicitud } from 'src/app/models/solicitud';
import { ArchivosCredito } from 'src/app/models/ArchivosCredito';
import { Global } from '../../../../services/global';

import { GeneralService } from 'src/app/services/general.service';


@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.css'],
  providers: [GeneralService],
})
export class CreditComponent implements OnInit {

  public activePayments = false;
  public activeSumary = true;
  public activeValidation = false;
  public solicitud: Solicitud;
  public datos;
  public total;
  public preaprobado: any;
  public pagos;
  public cargosDesembolso;
  public cargosCuota;
  public cuotaFija;
  public cuota;

  public id;
  public base64textString: String = "";
  public ArchivosCredito: ArchivosCredito;
  public arrayFiles: Array<any> = [];
  public lista_documentos;
  public documento;
  public respuesta_sendFiles;
  public documents_required: number = 0;
  public documents_added: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService
  ) {
    this.solicitud = new Solicitud(null, null, null, null, null, null, null, null, null, null);
    /* this.ArchivosCredito = new ArchivosCredito(123123, null); */
    //console.log(this.arrayFiles);
  }

  ngOnInit() {
    console.log('init credito')
    this.getSolicitud();    
    //this.getListaDocumentos();
    this.ArchivosCredito = new ArchivosCredito(this.id, null);

  }

  togglePayments() {
    console.log('entra 1');
    this.activeValidation = false;
    this.activePayments = true;
    this.activeSumary = false;    
  }

  toggleSumary() {
    console.log('entra 2');
    this.activeValidation = false;
    this.activeSumary = true;
    this.activePayments = false;
  }

  toggleValidation() {
    console.log('entra 3');
    this.activeValidation = false;
    this.activeSumary = true;
    this.activePayments = false;
  }

  getSolicitud() {

    this._route.params.subscribe(params => {
      this.id = +params['solicitud'];
      //console.log(this.id);
      let json = Global.json;
      json.body.inputParameters.push({ 'name': 'solicitud', 'value': this.id });
      json.body.serviceID.id = "16";

      //let json = this._generalService.dataJson(this.solicitud, '16');
      sessionStorage.setItem('id_solcitud',this.id);
      console.log('guarda solcitud')
      this._generalService.api(json).subscribe(
        response => {
          if (response) {
            this.datos = JSON.parse(response.body.outputParameters.value);
            console.log(this.datos);
            //active
            if(this.datos.estado=='Aprobado'){
              this.activeValidation = true;
            }
            if (this.datos.requiere_doc) {
              this.getListaDocumentos();
            }
            let prestamo = this.datos.monto.replace(/\./g, '');
            prestamo = parseInt(prestamo);

            this.preaprobado = prestamo - 17850;

            this.preaprobado = Global.formatNumber.new(this.preaprobado, '');

            this.pagos = this.datos.pagos;
          }

        },
        error => {
          console.log(error);
        }

      );

    });
  }

  getCargos() {

    let cargos = JSON.parse(localStorage.getItem('cargos'))

    if (cargos != undefined && cargos != null && cargos != '') {
      cargos = JSON.parse(cargos.value);
      //this.cargosDesembolso = cargos.cargos_desembolso;
      this.cargosCuota = cargos.cargos_cuota;
    } else {

      let json = Global.json;
      json.body.serviceID.id = "20";
      this._generalService.api(json).subscribe(
        response => {
          localStorage.setItem('cargos', JSON.stringify(response.body.outputParameters));
          this.cargosCuota = cargos.cargos_cuota;
          localStorage.setItem('cargos', JSON.stringify(response.body.outputParameters));
        },
        error => {
          console.log(<any>error)
        }
      );
    }

  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    //console.log(this);
    if (files && file) {
      var reader = new FileReader();
      this.base64textString = file.type;
      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;
    this.base64textString += "-"+btoa(binaryString);
    //console.log(this.base64textString);
    //this.arrayFiles.push({'tipo_archivo': this.documento, "archivo": this.base64textString});

    /* console.log(this.arrayFiles); */
  }

  getListaDocumentos() {

    let json = Global.json;
    json.body.serviceID.id = "53";
    json.body.inputParameters = Array({ 'name': 'estado', 'value': this.datos.estado_id });

    this._generalService.api(json).subscribe(
      response => {
        this.lista_documentos = JSON.parse(response.body.outputParameters.value);
        
        for(let documento of this.lista_documentos) {
          /* console.log(documento); */
          if(documento.required) {
            this.documents_required++;
          }
        }
        //console.log(this.documents_required);
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  sendFiles(form, e) {

    const btn_id_submit = e.submitter.id;
    var btn_id_submit_split = btn_id_submit.split('_');
    var id_documento: string = "";

    for(let i = 3; i < btn_id_submit_split.length; i++) {
      id_documento += btn_id_submit_split[i]+'_';
    }

    id_documento = id_documento.slice(0, -1);

    var spinner = document.getElementById('spinner_'+id_documento);
    var send_button_file = document.getElementById('send_button_file_'+id_documento);

    if(this.respuesta_sendFiles) {
      var msg_files = document.getElementById('msg_files');
      msg_files.hidden = true;
    }

    send_button_file.setAttribute('disabled', 'true');

    spinner.hidden = false;

    this.ArchivosCredito.archivos = JSON.stringify(this.arrayFiles[id_documento]);
    let json = this._generalService.dataJson(this.ArchivosCredito, '52');

    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          
          spinner.hidden = true;
          this.respuesta_sendFiles = JSON.parse(response.body.outputParameters.value);

          if(this.respuesta_sendFiles.message == 'Archivo guardado y solicitud radicada satisfactoriamente'){
            alert('Solicitud radicada con éxito, un analista de crédito se comunicará contigo');
          }

          let span_table = document.getElementById('span-' + id_documento);
          span_table.textContent = "Enviado";

          if(msg_files) {
            msg_files.hidden = false;
          }
        }
      },
      error => {
        console.log(error);
      }

    );

  }

  addFile() {

    let msj_sec = document.getElementById('msj-sec');
    if (this.base64textString) {
      msj_sec.hidden = true;

      var send_button_file = document.getElementById('send_button_file_'+this.documento);
      send_button_file.removeAttribute('disabled');

      this.arrayFiles[this.documento] = {'tipo_archivo': this.documento, "archivo": this.base64textString};

      let added_table = document.getElementById('documento-' + this.documento);
      added_table.className = added_table.className.replace(/(?:^|\s)fa-window-close error-td(?!\S)/g, ' fa-check-square success-td');

      let span_table = document.getElementById('span-' + this.documento);
      span_table.textContent = "Pendiente por enviar";
    } else {
      
      msj_sec.hidden = false;
      msj_sec.lastElementChild.lastElementChild.innerHTML = "Recuerda que debes adjutar un documento"
    }

  }

}
