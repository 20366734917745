import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  public identity;

  constructor(
      private _generalService: GeneralService,
  ) {
      this.loadUser();
  }

  ngOnInit() {
    this.loadUser();
  }
	
	loadUser() {

    this.identity = this._generalService.getIdentity();
  
  }

}
