import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HomeRoutingModule } from './home-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* import {MatDatepickerModule} from '@angular/material/datepicker';  */

import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { WizardFormComponent } from './components/wizard-form/wizard-form.component';
import { AboutComponent } from './components/about/about.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { MainSimulatorComponent } from './components/main-simulator/main-simulator.component';
import { SimulatorsModule } from '../simulators.module';
import { SimulatorComponent } from './components/simulator/simulator.component';
import { SimulatorCarComponent } from './components/simulator-car/simulator-car.component';

//import { WizardGuard } from '../../guards/wizard.guard';

@NgModule({
    declarations: [
        HomeComponent,
        LoginComponent,
        ErrorComponent,
        WizardFormComponent,
        AboutComponent,
        ForgetPasswordComponent,
        RecoverPasswordComponent
    ],
    imports: [
      CommonModule,
      HttpClientModule,
      FormsModule,
      HomeRoutingModule,
      BrowserAnimationsModule,
      SimulatorsModule,
      /* MatDatepickerModule */
    ],
    exports: [
        HomeComponent,
        LoginComponent,
        ErrorComponent,
        SimulatorComponent,
        SimulatorCarComponent
    ],
    providers: [/*WizardGuard*/],
    bootstrap: [/*HomeComponent*/]
  })
  export class HomeModule { }