import { Component, OnInit, DoCheck } from '@angular/core';

import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  providers: [GeneralService]
})
export class MainComponent implements OnInit, DoCheck {

  public active: boolean;
  public toggleMss = "Ocultar menu";
  public validate: boolean;
  public momento: string;

  public identity;

  constructor(
    private _generalService: GeneralService
  ) { 

    this.identity = this._generalService.getIdentity();
    this.momento =  'validar_usuario';

  }

  ngOnInit() {
    
    let user = sessionStorage.getItem('user');    

    if(user != null || user != '' || user != undefined) {
      sessionStorage.removeItem('user');
    }

    this.loadUser();
    this.validate = this.validateUser();

  }

  ngDoCheck() {

    this.loadUser();
    
  }

  toggle() {
    
    if(this.active) {
      this.active = false;
      this.toggleMss = "Ocultar Menu";
    } else {
      this.active = true;
      this.toggleMss = "Mostrar menu";
    }

  }

  loadUser() {

    this.identity = this._generalService.getIdentity();
  
  }

  validateUser() {
    
    if (this.identity.estado) {
      return false;
    } else {
      return true;
    }

  }

  setMomento() {
    this._generalService.momento$.emit('validar_usuario');
  }

}
