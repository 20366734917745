import { Component, OnInit } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public identity;

  constructor(
    private _generalService: GeneralService
  ) {
    this.identity = _generalService.getIdentity();
  }

  ngOnInit() {

	let user = sessionStorage.getItem('user');  	

	if(user != null || user != '' || user != undefined) {
  		sessionStorage.removeItem('user');
  	}
  	
  }

}
