import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignatureComponent } from './components/signature/signature.component'; 

const signatureRoutes: Routes = [
    { 	path: 'signature/main/:id', 
    	component: SignatureComponent, 
    	/*children: [
            { path: ':id', component: SignatureComponent },
        ]*/ 
    }
];
@NgModule({
    imports: [RouterModule.forRoot(signatureRoutes, { useHash: true})],
    providers: [],
    exports: [RouterModule]
})
export class signatureRoutingModule { }