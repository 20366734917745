import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-user',
  templateUrl: './error-user.component.html',
  styleUrls: ['./error-user.component.css']
})
export class ErrorUserComponent implements OnInit {

  public mensaje;

  constructor() { }

  ngOnInit() {
    this.mensaje = this.getMessage();
  }

  getMessage() {
    
    let mensaje = sessionStorage.getItem('mensaje');

    return JSON.parse(mensaje);
  }

}
