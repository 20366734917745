import { Component, OnInit, DoCheck } from '@angular/core';
import { PersonaDatosPersonalCuenta } from '../../../../models/PersonaDatosPersonalCuenta';
import { Global } from '../../../../services/global';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { GeneralService } from 'src/app/services/general.service';
import { Login } from 'src/app/models/login';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit, DoCheck {

	public isActive = true;
	public identity;
	public persona: PersonaDatosPersonalCuenta;
	public login: Login;
	public message: String;
	public user;
	public personaS: boolean;
	public messageCount;

	public inputuno;
	public inputdos;
	public inputtres;
	public inputcuatro;
	public solicitud;
	public twoStep;
	public threeStep;
	public messageCode;
	public finished;

  	constructor(
  		private _generalService: GeneralService,
  		private _route: ActivatedRoute,
  		private _router: Router
  	) { 
  		this.finished = false;
  		this.threeStep = false;
  		this.identity = this._generalService.getIdentity();

  		if (this.identity != null) {
  			this.twoStep = true;
  			this.persona = new PersonaDatosPersonalCuenta(
		  		this.identity.tipo_identificacion_id,
		  		this.identity.identificacion,
		  		this.identity.primer_nombre,
		  		this.identity.primer_apellido,
		  		this.identity.segundo_nombre,
		  		this.identity.segundo_apellido,
		  		this.identity.email,
		  		this.identity.fecha_de_nacimiento,
		  		this.identity.fecha_de_expedicion,
				this.identity.celular,
				this.identity.persona
	  		);
  		} else {
  			this.personaS = false;
  			this.persona = new PersonaDatosPersonalCuenta(null, null, '', '', '', '', '', null, null, null, null);
		}
		
		this.login = new Login(null, null);
  		
  	}

  	ngOnInit() {
  		this.loadUser();
  		this.solicitud = this.getSolicitud();
  	}

  	ngDoCheck() {

	    this.loadUser();
	    
	}

  	getDataUser(form) {
  		if (this.identity == null) {
  			
  			let json1 = this._generalService.dataJson(this.persona, "2");

		    this._generalService.api(json1).subscribe(
		      response => {
		      
		        if (response.body.outputParameters.value) {

		        	let data = JSON.parse(response.body.outputParameters.value);

		        	//this.user = data;

		        	let json = this._generalService.dataJson(data, "23");

			  		let inputParameters = {"name": "solicitud", "value": this.solicitud};

					json.body.inputParameters.push(inputParameters);

					let id = data.id;
					let celular = data.cellphone;
					let email = data.email;

			    	this._generalService.api(json).subscribe(
			    		response => {
			    		
			    			let x = response.body.outputParameters.value;

			    			if(x) {

			    				this.user = data;
			    				this.persona.email = data.email;
					          	this.persona.celular = data.celular;
					          	//this.persona._id = data.id;
					          	this.twoStep = true;
					          	sessionStorage.setItem('user', JSON.stringify(this.user));

			    			} else {

			    				//this.persona.email = '';
					          	this.persona.celular = null;
					          	//this.persona._id = '';

					          	this.user = null;

					          	this.messageCount = "Esta cuenta no pertence a la solicitud que se firmara"
					          	
			    			}

			    		}, error => {
			    			console.log(<any>error);
			    		}
			    	);

		        } else {
		          this.message = response.header.messages;
		        }
		      
		      }, 
		      error => {
		        console.log(<any>error);
		      }
		    );
  		}

  	}

  	pssShowHide(input: any): any {
    
	    if(input.type == 'password') {
	      input.type = 'text';
	      this.isActive = false;
	    } else {
	      input.type = 'password';
	      this.isActive = true;
	    }
	}

	loadUser() {
		this.user = JSON.parse(sessionStorage.getItem('user'));

		if(this.user != null) {
			this.persona.email = this.user.email;
	      	this.persona.celular = this.user.celular;
	      	//this.persona._id = this.user.id;

			return this.user;
		}
		
	}

	onSend(form) {

		this.threeStep = true;

		let json = this._generalService.dataJson(this.persona, "18");

		let inputParameters = {"name": "solicitud", "value": this.solicitud};

    	json.body.inputParameters.push(inputParameters);

		this._generalService.api(json).subscribe(
		    response => {
		    	console.log(response);
			}, 
			error => {
				console.log(<any>error)
			}
		);
	}

	onCode(form) {

		let json = Global.json;

		let code = this.inputuno+this.inputdos+this.inputtres+this.inputcuatro;

		let inputParameters = {"name": "code", "value": code};

		json.body.serviceID.id = '22';
		json.body.inputParameters.push(inputParameters);

		this._generalService.api(json).subscribe(
		    response => {
		    	this.finished = true;
		    	this.messageCode = "Tus documentos fueron firmados exitosamente, te hemos enviado un correo un con la copia de ellos"
			}, 
			error => {
				console.log(<any>error)
			}
		);		

	}

	getSolicitud() {

		let id;

		this._route.params.subscribe(params => {

  			id = params['id'];
  		
  		});

  		return id;
	}

}
