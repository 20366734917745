import { Component, DoCheck, OnInit, Inject } from '@angular/core';
import { GeneralService } from './services/general.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [GeneralService]
})
export class AppComponent implements DoCheck, OnInit {
  
   public identity;
   public url;

  constructor(
      private _generalService: GeneralService,
      @Inject(DOCUMENT) document: Document
  ) {
      this.loadUser();
      this.url = document.location.href.split("#");
      this.url = this.url[1];
      console.log(this.url);
  }

  ngOnInit() {
    this.topFunction();
  }

  ngDoCheck() {
    this.loadUser();
  }
	
	loadUser() {

    this.identity = this._generalService.getIdentity();
  
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

}
