import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Global } from '../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  public correo: string;
  public message: string;
  public alert: string;

  constructor(
    private _generalService: GeneralService
  ) { }

  ngOnInit() {
  }

  onSubmit(form) {
    let json = Global.json;
    json.body.serviceID.id = '48';
    json.body.inputParameters.push({ 'name': 'correo', 'value': this.correo });

    this._generalService.api(json).subscribe(

      response => {
        if (response) {
          /* console.log(response); */
          /* let header = JSON.parse(response.header); */
          this.message = response.header.messages;

          let body = JSON.parse(response.body.outputParameters.value);
          this.alert = body.alert;

        }
      },
      error => {
        console.log(error);
      }
    );
  }

}
