import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';

import { Global } from '../services/global';
import { GeneralService } from 'src/app/services/general.service';

//Intento de guard para validar solicitudes
@Injectable()
export class ValidateRequestGuard implements CanActivate {

	private return: boolean;

    constructor(
    	private _generalService: GeneralService, 
    	private _router: Router
    ) { 

    }

    canActivate() {

	   	let json = Global.json;

	    json.body.serviceID.id = '14';
	    let personID =localStorage.getItem('id');
	    let inputParameters = {"name": "id", "value":personID};

	    json.body.inputParameters.push(inputParameters);

	    this._generalService.api(json).subscribe(
	      response => {
	        let solicitudes = JSON.parse(response.body.outputParameters.value);
	        console.log(solicitudes.length);
	        if(solicitudes.length == 3) {
	        	this._router.navigate(['/main/user/credits']);
	        	this.return = false;
	        } else {
	        	this.return = true;
	        }
	      },
	      error => {
	        console.log(<any>error);
	      }
	    );

	    return this.return;
	  
    }
}