import { Component, OnInit, OnDestroy } from '@angular/core';
import { Kushki } from '@kushki/js';

import { Global } from '../../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transfer-in',
  templateUrl: './transfer-in.component.html',
  styleUrls: ['./transfer-in.component.css']
})
export class TransferInComponent implements OnInit {

  private kushki: Kushki;
  public token;
  public kushki_bank;
  public bancos;
  public userType: any;
  public identity;
  public description;
  public payment_ready: boolean;
  public loading_payment: boolean;
  public text_btn_payment: string;
  public status_payment;
  public comprobante_href;
  public solicitud: number;
  public fecha_hoy: string;
  public mensaje_dia_no: string;
  public valor;
  public btn_payment_enable: boolean;
  public valor_string: string;
  public valor_html: string;
  public validate_mount: boolean;
  public bank_name: string;

  public solicitudSuscription: Subscription;
  constructor(
    private _generalService: GeneralService,
    private _router: Router,
  ) {

    this.identity = this._generalService.getIdentity();
    this.userType = '0';
    this.payment_ready = false;
    this.loading_payment = false;
    this.text_btn_payment = "Pagar";
    this.solicitud = null;
    this.btn_payment_enable = true;
    this.description = "Pago de cuota credito MoneyTech";
    this.kushki_bank = 0;
    this.validate_mount = true;

    let fecha = new Date();
    this.fecha_hoy = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate();

    this.kushki = new Kushki({
      merchantId: Global.public_kuski_key/* '476539a5b9d9465db0d55a61136509c0' */,
      inTestEnvironment: false,
    });

  }

  ngOnInit() {

    /* this.solicitudSuscription = this._generalService.solicitud$.subscribe( solicitud => {
      this.solicitud = solicitud;
      console.log("TRANSFERENCIA"+this.solicitud);
    }); */

    this.solicitud = parseInt(sessionStorage.getItem('solicitud'));
    sessionStorage.removeItem('solicitud');

    this.kushkiRequestPseBankList();

  }

  /* ngOnDestroy() {
    this.solicitudSuscription.unsubscribe();
  } */

  getValor() {
    let json = Global.json;
    json.body.serviceID.id = '44';
    json.body.inputParameters.push({ 'name': 'solicitud', 'value': this.solicitud });

    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.valor = JSON.parse(response.body.outputParameters.value);
          this.valor_html = this.valor.valor;

          this.valor_string = this.valor.valor.toString();
          this.valor_string = this.valor_string.replace(/\,/g, '');
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  initKushki() {
    //console.log(this.description);
    this.setDataHtml(true, "Generando pago");
    var callback = this.callbackResponseTransferInit.bind(this);

    this.valor_string = this.valor.valor.toString();
    this.valor_string = this.valor_html.replace(/\,/g, '');
    //console.log(this.valor_string);
    this.kushki.requestTransferToken({
      bankId: this.kushki_bank, // Only required for Colombian merchants
      callbackUrl: 'http://app.moneytech.com.co/#/main/user/voucher', /* 'http://localhost:4200/#/main/user/voucher', */ //'http://localhost:4200/#/main/user/voucher/', // Not required for Mexico
      userType: this.userType,
      documentType: this.identity.tipo_identificacion_abreviatura,
      documentNumber: /* '123456789', */ /* '100000002', *//*  '999999990', */ this.identity.identificacion,
      paymentDesc: this.description,
      email: this.identity.email,
      currency: 'COP',
      amount: {
        subtotalIva: 0,
        subtotalIva0: parseInt(this.valor_string),
        iva: 0,
        extraTaxes: {
          propina: 0,
          tasaAeroportuaria: 0,
          agenciaDeViajes: 0,
          iac: 0
        }
      }
    },
      callback); // Also you can set the function directly

  }

  callbackResponseTransferInit(response) {

    var spinner = document.getElementById('spinner');
    var btn_payment = document.getElementById('btn-payment');

    if (!response.code) {

      let param =
      {
        "token": response.token,
        "amount": {
          "subtotalIva": 0,
          "subtotalIva0": parseInt(this.valor_string),
          "iva": 0,
          "extraTaxes": {
            "propina": 0,
            "tasaAeroportuaria": 0,
            "agenciaDeViajes": 0,
            "iac": 0
          }
        },
        "metadata": {
          "solicitud": this.solicitud,
          "persona": this.identity.persona,
          "bank_name": this.bank_name,
          "description": this.description
        }
      }

      let body_mh = {
        'solicitud': this.solicitud,
        'persona': this.identity.persona
      };

      let json = Global.json;
      json.body.serviceID.id = '40';
      json.body.inputParameters.push({ 'name': 'body_mh', 'value': body_mh });
      json.body.inputParameters.push({ 'name': 'body', 'value': param });
      json.body.inputParameters.push({ 'name': 'solicitud', 'value': this.solicitud });

      this._generalService.api(json).subscribe(

        response => {
          if (response) {
            let outputParameters = JSON.parse(response.body.outputParameters.value);
            if (outputParameters.status && outputParameters.status == 'success') {
              //console.log(outputParameters);
              let comprobante = document.getElementById('comprobante');
              comprobante.hidden = false;
              let init_response = JSON.parse(outputParameters.response);
              comprobante.attributes[3].value = init_response.redirectUrl;
              //console.log(init_response);
              btn_payment.textContent = "Pago realizado";
              let alert = document.getElementById('success');
              alert.hidden = false;
              sessionStorage.setItem('token', outputParameters.token);
              //window.open(init_response.redirectUrl, '_blank');
              window.location.href = init_response.redirectUrl;
            } else {

              let alert = document.getElementById('warning');
              alert.hidden = false;

            }

            spinner.hidden = true;
          } else {

            spinner.hidden = true;
            let alert = document.getElementById('warning');
            alert.hidden = false;

          }
          btn_payment.hidden = true;
        }, error => {
          btn_payment.hidden = true;
          spinner.hidden = true;
          let alert = document.getElementById('danger');
          alert.hidden = false;
          console.log(<any>error);

        }
      );

    } else {

      spinner.hidden = true;
      let alert = document.getElementById('danger');
      alert.hidden = false;
      console.error('Error: ', response.error, 'Code: ', response.code, 'Message: ', response.message);

    }

  }

  kushkiRequestPseBankList() {

    /* var callback = response => {
      if(!response.code){
        this.bancos = response;
        console.log(this.bancos);
      } else {
        console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
      }
    }
    
    this.kushki.requestPseBankList(callback);  */
    let json = Global.json;
    json.body.serviceID.id = '41';
    this._generalService.api(json).subscribe(

      response => {
        if (response) {
          this.bancos = JSON.parse(response.body.outputParameters.value);
          this.payment_ready = true;
          this.getValor();
          //console.log(this.bancos);
        }
      }, error => {
        console.log(<any>error);
      }
    );

  }

  checkUser(e) {

    e.preventDefault()

    var person_selected = document.getElementsByClassName('person-selected')[0];
    person_selected.className = 'btn btn-link';

    e.target.className = 'btn btn-link person-selected';

    if (e.target.text == 'Juridica') {
      this.userType = '1';
    }

    if (e.target.text == 'Natural') {
      this.userType = '0';
    }

    //console.log(this.userType);

  }

  setDataHtml(loading, message) {
    this.loading_payment = loading;
    this.text_btn_payment = message;
  }

  getTextBtnPayment() {
    return this.text_btn_payment;
  }

  getLoadingPayment() {
    return this.text_btn_payment;
  }

  format(input) {
    var num = input.value.replace(/\,/g, '');
    //console.log(num, this.valor_string);
    if (parseInt(num) >= parseInt(this.valor_string)) {
      this.validate_mount = true;
    } else {
      this.validate_mount = false;
    }
    if (!isNaN(num)) {
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\,?)(\d{3})/g, '$1,');
      num = num.split('').reverse().join('').replace(/^[\,]/, '');
      input.value = num;
    }

  }

  clean(e) {
    this.validate_mount = false;
    //e.value = "0";
    this.valor_html = "";
    let valor_por_defecto = document.getElementById('valor-por-defecto');
    valor_por_defecto.hidden = false;

    let checkbox_value_default = document.getElementById('default') as HTMLInputElement;
    checkbox_value_default.removeAttribute('disabled');
    checkbox_value_default.checked = false;

  }

  checkValue(e) {
    let event = e.target;

    if (event.checked) {
      this.valor_html = this.valor.valor;
      this.validate_mount = true;
      e.target.setAttribute('disabled', 'true');
    }

    //console.log(this.validate_mount);
  }

  goToLink(url: string) {
    window.open(url);
  }

  getNameBank() {
    let select_bank = document.getElementById("select_bank") as HTMLSelectElement;
    this.bank_name = select_bank.options[select_bank.selectedIndex].text;
  }

}