import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Global } from '../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  public correo: string;
  public load: boolean;
  public isActive: boolean;
  public contrasena: string;
  public message: string;
  public alert: string;
  public pss;
  public pssV: boolean;
  public confirmPss: boolean;
  public mssPssError: boolean;

  constructor(
    private _generalService: GeneralService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {

    this.load = true;
    this.contrasena = '';

  }

  ngOnInit() {
    this.getTokenMHRecoverPassword();
    this.load = false;
  }

  getParam() {

    let token;

    this._route.params.subscribe(params => {

      token = params['token'];

    });

    return token;
  }

  getTokenMHRecoverPassword() {

    var token = this.getParam();

    let json = Global.json;
    json.body.serviceID.id = '49';
    json.body.inputParameters.push({ 'name': 'token', 'value': token });

    this._generalService.api(json).subscribe(

      response => {
        if (response) {
          /* console.log(response); */
          /* let header = JSON.parse(response.header); */
          /* this.message = response.header.messages;
          */
          let body = JSON.parse(response.body.outputParameters.value);

          if (body.status == 'success') {
            this.load = false;
            this.correo = body.correo;
          } else {
            this._router.navigate(['/login']);
          }

        }
      },
      error => {
        console.log(error);
      }
    );
  }

  pssShowHide(input: any): any {

    if (input.type == 'password') {
      input.type = 'text';
      this.isActive = false;
    } else {
      input.type = 'password';
      this.isActive = true;
    }

  }

  onSubmit(form) {
    let json = Global.json;
    json.body.serviceID.id = '50';
    json.body.inputParameters.push({ 'name': 'correo', 'value': this.correo });
    json.body.inputParameters.push({ 'name': 'contrasena', 'value': this.contrasena });

    this._generalService.api(json).subscribe(

      response => {
        if (response) {
          /* console.log(response); */
          /* let header = JSON.parse(response.header); */
          /* this.message = response.header.messages;
          */
          this.message = response.header.messages;

          let body = JSON.parse(response.body.outputParameters.value);
          this.alert = body.alert;

        }
      },
      error => {
        console.log(error);
      }
    );
  }

  psswV() {
    this.pssV = true;

    if (this.contrasena != '') {
      this.comparePss();
    }

  }

  comparePss() {

    if (this.pss == this.contrasena) {
      this.confirmPss = true;
      this.mssPssError = false;
    } else {
      this.mssPssError = true;
      this.confirmPss = false;
    }

  }


}
