import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { GeneralService } from 'src/app/services/general.service';
import { GenerateOtp } from 'src/app/models/GenrateOtp';
import { Router } from '@angular/router';
import { Otp } from 'src/app/models/Otp';
import { Subscription, empty } from 'rxjs';
import { Global } from '../../../../services/global';
import { IniciarOtp } from 'src/app/models/IniciarOtp';
import * as shajs from 'sha.js';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit, OnDestroy {

  @Input() titulo: string;

  public load: boolean;
  public status;
  public status_VOTP;
  public message: string;
  public generated: boolean;
  public validated: boolean;
  public regValidacion: string;
  public idTransaccionOTP: string;
  public chronometer: boolean;
  public cronometro;

  public generateOtp: GenerateOtp;
  public iniciarOtp: IniciarOtp;
  public otp: Otp;
  public identity;
  public nombre;
  public codigoOtp: string;
  public momento: string;
  public solicitud: string;
  public datos_solicitud: any;
  public mensaje_terminos_condiciones: string;
  public terminos_condiciones: boolean;
  public mostrarOtp: boolean;
  public disabled_bt_terms: boolean;
  public simulador_response: any;

  public momentoSuscription: Subscription;
  public solicitudSuscription: Subscription;
  public DatosSolicitudSuscription: Subscription;

  constructor(
    private _router: Router,
    private _generalService: GeneralService
  ) {

    this.identity = this._generalService.getIdentity();
    this.mensaje_terminos_condiciones = null;

    this.nombre = this.identity.primer_nombre + ' ' + this.identity.segundo_nombre + ' ' + this.identity.primer_apellido + ' ' + this.identity.segundo_apellido;
    this.validated = false;
    this.generated = false;

    this.codigoOtp = null;
    this.solicitud = null;
    this.disabled_bt_terms = false;
    this.datos_solicitud = empty;
  }

  ngOnInit() {
    console.log('initi otp')
    this.momentoSuscription = this._generalService.momento$.subscribe(momento => {
      this.momento = momento;
    });

    this.solicitudSuscription = this._generalService.solicitud$.subscribe(solicitud => {
      this.solicitud = solicitud;
    });

    this.DatosSolicitudSuscription = this._generalService.datos_solicitud$.subscribe(datos_solicitud => {
      this.datos_solicitud = datos_solicitud;
      this.simulador_response = datos_solicitud.resumen;
    });
    
    this.loadUser();

  }

  ngOnDestroy() {
    this.momentoSuscription.unsubscribe();
    this.solicitudSuscription.unsubscribe();
    this.DatosSolicitudSuscription.unsubscribe();
  }

  loadUser() {

    this.identity = this._generalService.getIdentity();

  }

  generateOTP() {
    console.log('genera otp')
    this.solicitudSuscription = this._generalService.solicitud$.subscribe(solicitud => {
      this.solicitud = solicitud;
    });

    let fecha = Global.fechaUnix(this.identity.fecha_de_expedicion);
    //let fecha = Global.fechaUnix("2007-11-16");
    //console.log(this.identity)
    //exit;
    this.load = true;
    this.iniciarOtp = new IniciarOtp(
      "electroventas",
      "expco_evidente_master",
      this.identity.identificacion,
      this.nombre,
      this.identity.primer_apellido,
      this.identity.segundo_apellido,
      this.identity.celular,
      fecha
    );

    /*this.iniciarOtp = new IniciarOtp(
      "fundafast",
      "expco_evidente_master",
      1143828114,
      "johanna",
      "peña",
      "sabogal",
      3182245873,
      fecha
    );*/


    let json = this._generalService.dataJson(this.iniciarOtp, '57');
    
    //console.log(JSON.stringify(json));

    this._generalService.apiServicios(json).subscribe(

      response => {
        //console.log(response);
        
        if (response) {
          
          if (response.body) {
            let data = JSON.parse(response.body.outputParameters.value);
            //console.log(data);

            if (data.ResultadoGeneracion.resultadoOTP != "false") {
            //if (data) {

              this.status = 'success';
              this.chronometer = true;
              this.message = "Código generado satisfactoriamente";
              this.generated = true;

              var n = 60;
              var l = document.getElementById("chronometer")

              this.cronometro = setInterval(() => {

                n--;
                if (n == 0) {
                  this.chronometer = false;
                  this.status = 'warning';
                  this.message = "El tiempo se ha agotado";
                  l.innerHTML = "";
                  sessionStorage.setItem('momento', this.momento);
                  clearInterval(this.cronometro);

                } else {
                  l.innerHTML = "Tiempo restante: " + n.toString() + " segundos";
                }

              }, 1000);

              this.regValidacion = data.regValidacion
              this.idTransaccionOTP = data.ResultadoGeneracion.idTransaccionOTP;

            } else {

              this.generated = true;
              this.regValidacion = data.regValidacion;
              this.message = "No se ha podido generar el código";
              sessionStorage.setItem('momento', this.momento);
              this.status = 'warning';
            }
          } else {
            this.status = 'danger';
            this.message = "Ha ocurrido un error al generar código";
            this.generated = true;
          }

          this.load = false;

        }
        

      },
      error => {
        this.status = 'danger';
        this.message = "Ha ocurrido un error al generar código";
        this.generated = true;
        this.load = false;
        console.log(<any>error)
      }
    );
    
  }

  onSubmitValidateOtp(form) {
    this.status = null;
    var l = document.getElementById("chronometer")
    l.innerHTML = "";
    clearInterval(this.cronometro);
    this.load = true;
    //this.solicitud = localStorage.getItem('id_solcitud');    
    this.solicitudSuscription = this._generalService.solicitud$.subscribe(solicitud => {
      this.solicitud = solicitud;
    });

    console.log('solicitud=>'+this.solicitud)

    var Sha256 = {};  // Sha256 namespace

    this.otp = new Otp(
      "electroventas",
      "expco_evidente_master",
      this.regValidacion,
      this.idTransaccionOTP,
      //shajs('sha256').update(this.codigoOtp).digest('hex'),
      this.codigoOtp,
      this.identity.identificacion,
      this.identity.email,
      //this.momento,
      this.solicitud,
      this.identity.persona
    );

    /*this.status = 'success';
    this.message = 'Codigo validado';    

    this.identity.estado = true;*/

    
    let json = this._generalService.dataJson(this.otp, '36');
    
    this._generalService.api(json).subscribe(

      response => {
        console.log('entra');
        if (response) {
          console.log(response);
          let data = JSON.parse(response.body.outputParameters.value);
          data = JSON.parse(data);
          console.log(data.VerificarCodigoOTPRespuesta);
          if (data.VerificarCodigoOTPRespuesta.codigoValido == "true") {
            this.status = 'success';
            //this.message = data.VerificarCodigoOTPRespuesta.mensajeValidacion;
            this.message = 'Se validó tu identidad con éxito, próximamente recibirás un correo para firmar tus documentos electrónicamente';

            this.identity.estado = true;

            sessionStorage.setItem('identity', JSON.stringify(this.identity));
          } else {

            sessionStorage.setItem('momento', this.momento);

            if (this.momento == 'firma') {
              sessionStorage.setItem('solicitud', this.solicitud.toString());
            }

            this.chronometer = false;
            this.status = 'warning';
            this.message = data.VerificarCodigoOTPRespuesta.mensajeValidacion;
          }

          this.load = false;
          this.validated = true;
        }


      },
      error => {
        this.status = 'danger';
        this.message = "Ha ocurrido un error al validar";
        this.load = false;
        this.validated = true;
        console.log(<any>error)
      }
    );

  }

  goToQuestions(e) {
    e.preventDefault();
    sessionStorage.setItem('momento', this.momento);
    this._router.navigate(['/main/user/questions/' + this.regValidacion]);
  }

  ternsAndConditions(e) {

    this.disabled_bt_terms = true;

    var target = e.target;

    if (target.value == 'false') {
      this.mensaje_terminos_condiciones = "Al dar click en enviar usted rechaza terminos y condiciones, posterior a ello se rechazaria esta solicitud";
      this.terminos_condiciones = false;
    }

    if (target.value == 'true') {
      this.mensaje_terminos_condiciones = "Al dar click en enviar usted acepta terminos y condiciones y continuaria con el proceso";
      this.terminos_condiciones = true;
    }

  }

  sendTernsAndConditions() {

    if (!this.terminos_condiciones) {

      let json = Global.json;
      json.body.serviceID.id = '47';
      json.body.inputParameters.push({ 'name': 'solicitud', 'value': this.solicitud });
      json.body.inputParameters.push({ 'name': 'terminos_condiciones', 'value': this.terminos_condiciones });

      //console.log(json);
      this._generalService.api(json).subscribe(
        response => {
          if (response) {
            
            let section_terminos_y_condiciones = document.getElementById('terminos-y-condiciones');
            //console.log(section_terminos_y_condiciones);
            section_terminos_y_condiciones.style.display = "none";
            this.status = 'secondary';
            this.message = 'Esta solicitud para a ser rechaza';
            this.mostrarOtp = false;
          }
        },
        error => {
          console.log(error);
        }
      );

    } else {
      this.mensaje_terminos_condiciones = null;
      this.mostrarOtp = true;

    }
  }

  changeIcon(e) {
    //console.log(e.target.parentElement.childNodes[1].firstChild);
    let clase = e.target.parentElement.childNodes[1].firstChild.className;

    if (clase == "fas fa-chevron-down") {
      e.target.parentElement.childNodes[1].firstChild.className = "fas fa-chevron-right";
    } else {
      e.target.parentElement.childNodes[1].firstChild.className = "fas fa-chevron-down";
    }
    //console.log(e.target.firstElementChild.firstChild);
  }

}
