import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimulatorCarComponent } from './home/components/simulator-car/simulator-car.component'
import { SimulatorComponent } from './home/components/simulator/simulator.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { userRoutingModule } from './user/user-routing.module';
import { MainSimulatorComponent } from './home/components/main-simulator/main-simulator.component';
//import { MainSimulatorComponent } from './home/components/main-simulator/main-simulator.component';

@NgModule({
  declarations: [
    MainSimulatorComponent,
    SimulatorCarComponent,
    SimulatorComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    userRoutingModule,
  ],
  exports:[
    MainSimulatorComponent,
    SimulatorCarComponent,
    SimulatorComponent
  ]
})
export class SimulatorsModule { }
