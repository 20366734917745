export class PreguntaRespuesta {
    constructor(
        public preguntas: Array<any>,
        public regCuestionario: string,
        public numero_documento: number,
        public idCuestionario: string,
        public email: string,
        public preguntas_respuestas: string,
        public momento: string,
        public solicitud: number,
        public persona: number,
        public regValidacion: string
    ) {}
}