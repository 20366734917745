import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { HomeUserComponent } from './components/home-user/home-user.component';
import { CreditsComponent } from './components/credits/credits.component';
import { CreditDeclinedComponent } from './components/credit-declined/credit-declined.component';
import { PayComponent } from './components/pay/pay.component';
import { CreditComponent } from './components/credit/credit.component'
import { LoginComponent } from '../home/components/login/login.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { RequestNewComponent } from './components/request-new/request-new.component';
import { CreditRequestComponent } from './components/credit-request/credit-request.component';
import { SimulatorComponent } from '../home/components/simulator/simulator.component';
import { MainCreditRequestComponent } from './components/main-credit-request/main-credit-request.component';
import { UserActivationComponent } from './components/user-activation/user-activation.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { TransferInComponent } from './components/kushki/transfer-in/transfer-in.component';
import { CashInComponent } from './components/kushki/cash-in/cash-in.component';
import { VoucherComponent } from './components/kushki/voucher/voucher.component';
import { TransactionListComponent } from './components/transaction-list/transaction-list.component';
import { IdentityGuard } from 'src/app/guards/identity.guard';
import { ValidateRequestGuard } from 'src/app/guards/request.guard';
import { UserActivation } from 'src/app/guards/userActivation.guard';
import { UserValidation } from 'src/app/guards/user-validation.guard';
import { KushkiTokenValidation } from 'src/app/guards/kushki-token-validation.guard'

const userRoutes: Routes = [
    {
        path: 'main/user',
        component: MainComponent,
        canActivate: [IdentityGuard],
        children: [
            { path: '', component: HomeUserComponent },
            { path: 'home', component: HomeUserComponent },
            { path: 'credits', component: CreditsComponent },
            { path: 'profile', component: UserEditComponent },
            { path: 'credit-declined/:idestado', component: CreditDeclinedComponent },
            /* { path: 'pay/:solicitud', component: PayComponent }, */
            { path: 'credit/:solicitud', component: CreditComponent },
            { path: 'request-new', component: RequestNewComponent },
            /* { path: 'main-credit-request/:tipo', component: MainCreditRequestComponent, canActivate: [UserValidation] }, */
            { path: 'main-credit-request', component: MainCreditRequestComponent, canActivate: [UserValidation] },
            { path: 'questions/:regValidacion', component: QuestionsComponent },
            { path: 'user-activation', component: UserActivationComponent, canActivate: [UserActivation] },
            /* { path: 'voucher', component: VoucherComponent, canActivate: [KushkiTokenValidation] },
            { path: 'transaction-list', component: TransactionListComponent }, */
        ]
    },
    { path: 'logout/:sure', component: LoginComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(userRoutes, { useHash: true})],
    providers: [ValidateRequestGuard],
    exports: [RouterModule]
})
export class userRoutingModule { }