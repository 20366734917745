import { Component, OnInit } from '@angular/core';
import { PersonaDatosPersonalCuenta } from '../../../../models/PersonaDatosPersonalCuenta';
import { Labor } from '../../../../models/labor';

import { GeneralService } from 'src/app/services/general.service';


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css'],
  providers: [GeneralService]
})
export class UserEditComponent implements OnInit {

	public identity;
	public person: PersonaDatosPersonalCuenta;
  	public labor: Labor;
	public message;
  	public messageLabor;
	public status;

  constructor(
  	private _generalService: GeneralService,
  ) { 

  	this.identity = this._generalService.getIdentity();

  	this.person = new PersonaDatosPersonalCuenta(
  		this.identity.tipo_identificacion_id,
  		this.identity.identificacion,
  		this.identity.primer_nombre,
  		this.identity.primer_apellido,
  		this.identity.segundo_nombre,
  		this.identity.segundo_apellido,
  		this.identity.email,
  		this.identity.fecha_de_nacimiento,
  		this.identity.fecha_de_expedicion,
		this.identity.celular,
		this.identity.persona
	);
	  
  }

  ngOnInit() {
  }

  onSubmit(form) {

  	let json = this._generalService.dataJson(this.person, '17');

  	this._generalService.api(json).subscribe(

  		response => {
    		let data = JSON.parse(response.body.outputParameters.value);
  			this.status = data.status;
  			this.message = data.message;
  			if(this.status == 'success') {

  				this.identity.primer_nombre = data.primer_nombre;
  				this.identity.primer_apellido = data.primer_apellido;
  				this.identity.segundo_nombre = data.segundo_nombre;
  				this.identity.segundo_apellido = data.segundo_apellido;
  				this.identity.celular = data.celular;
  				
  				sessionStorage.setItem('identity', JSON.stringify(this.identity));
  			} 
			
  		},
  		error => {
  			console.log(<any>error)
  		}	
  	);

  }

}
