import { Component, OnInit } from '@angular/core';
import { Router  } from '@angular/router';
import { Global } from '../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';
import { Calculo } from 'src/app/models/calculo';
import { Solicitud } from 'src/app/models/solicitud';

@Component({
  selector: 'app-simulator-car',
  templateUrl: './simulator-car.component.html',
  styleUrls: ['./simulator-car.component.css'],
  providers: [GeneralService],
})
export class SimulatorCarComponent implements OnInit {

  public solicitud: Solicitud

  public identity;
  public formatNumber;
  public cargos: any;

  /* Variables de validacion */
  public calculate = false;
  public isCalculated;

  /* Variables demo */
  public montoRange: number;
  public montoRangeLabel: string;
  public tiempoRange: number;
  public tiempoRangeLabel: string;

  public calculo;
  public simulador_response: any;

  constructor(
    private _router: Router,
    private _generalService: GeneralService
  ) {

    this.identity = _generalService.getIdentity();

    this.montoRange = 50000000;
    this.tiempoRange = 6;    

    this.formatNumber = {
      separador: ".", // separador para los miles
      sepDecimal: ',', // separador para los decimales
      formatear: function (num) {
        num += '';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
          splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return this.simbol + splitLeft + splitRight;
      },
      new: function (num, simbol) {
        this.simbol = simbol || '';
        return this.formatear(num);
      }
    }

    this.montoRangeLabel = this.formatNumber.new(this.montoRange);  
    
  }

  ngOnInit() {
    //this.getCargos();
    this.restCalcular();
    this.topFunction();
  }

  wizard(form) {
    sessionStorage.setItem('isCalculated', '1');
    this.setDataStorageDemo();
    this._router.navigate(['/wizard-form']);
  }

  getIsCalculated() {
    this.isCalculated = sessionStorage.getItem('isCalculated');
  }

  format(input) {
    var num = input.value.replace(/\./g, '');
    if (!isNaN(num)) {
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      num = num.split('').reverse().join('').replace(/^[\.]/, '');
      input.value = num;
    }

  }


  getCargos() {

    let json = Global.json;
    json.body.serviceID.id = "20";
    this._generalService.api(json).subscribe(
      response => {
        let cargos = JSON.parse(response.body.outputParameters.value);
        this.cargos = cargos.cargos_cuota;
        localStorage.setItem('cargos', JSON.stringify(response.body.outputParameters));
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  /* DEMO FUNCTIONS */

  changeRangeMonto(e) {

    this.montoRangeLabel = this.formatNumber.new(e.target.value);
    this.montoRange = e.target.value;

    this.restCalcular();

  }

  changeRangeDias(e) {

    this.tiempoRange = e;

    if (this.tiempoRange > 1) {
      this.tiempoRangeLabel = this.tiempoRange + ' meses';
    } else {
      this.tiempoRangeLabel = this.tiempoRange + ' mes';
    }

    this.restCalcular();

  }

  restCalcular() {
    this.calculo = new Calculo(this.montoRange, this.tiempoRange, 15);

    let json = this._generalService.dataJson(this.calculo, "51");
   
    this._generalService.api(json).subscribe(
      response => {
        if (response) {

          this.simulador_response = JSON.parse(response.body.outputParameters.value);
          //console.log(this.simulador_response);
          if (this.identity) {
            
            

            let date = new Date();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            let fecha_desembolso = day + '/' + month + '/' + year;
            let fecha_primer_pago = this.sumFecha(30, fecha_desembolso);

            let producto = 15;
            let destino = 4;
            let frecuencia_de_pago = 6;
            let interes = 20.4127;

            this.solicitud = new Solicitud(
              this.montoRange.toString(),
              this.identity.persona,
              fecha_primer_pago,
              producto,
              destino,
              frecuencia_de_pago,
              this.tiempoRange,
              interes,
              fecha_desembolso,
              this.identity.convenio_id
            );

            let solicitud_json = {'solicitud': null, 'cuota': null}
          
            solicitud_json.solicitud = this.solicitud;
            solicitud_json.solicitud.total_desembolso = this.simulador_response["TotalDes"];            
            solicitud_json.solicitud.cuota_total = this.simulador_response["TotalCuo"];
            solicitud_json.solicitud.monto_format = this.montoRangeLabel;
            solicitud_json.solicitud.ultima_cuota_total = this.simulador_response["TotalUltimaCuo"];
            solicitud_json.solicitud.total_a_pagar = this.simulador_response.TotalResumen;
            solicitud_json.cuota = this.simulador_response.cuotas[0]['Cuota fija'];  
                      
            sessionStorage.setItem('solicitud', JSON.stringify(solicitud_json));

          }

        }
      },
      error => {
        console.log(<any>error);
      }
    );
  }

  setDataStorageDemo() {
    /*let data = {
      'monto': this.montoRange,
      'plazo': this.tiempoRange,
      'cuota': this.cuota,
      'cuotaFija': this.cuotaFija
    }

    sessionStorage.setItem('data', JSON.stringify(data));*/

  }

  changeIcon(e) {
    //console.log(e.target.parentElement.childNodes[1].firstChild);
    let clase = e.target.parentElement.childNodes[1].firstChild.className;

    if (clase == "fas fa-chevron-down") {
      e.target.parentElement.childNodes[1].firstChild.className = "fas fa-chevron-right";
    } else {
      e.target.parentElement.childNodes[1].firstChild.className = "fas fa-chevron-down";
    }
    //console.log(e.target.firstElementChild.firstChild);
  }

  sumFecha (d, fecha) {
    var Fecha = new Date();
    var sFecha = fecha || (Fecha.getDate() + "/" + (Fecha.getMonth() + 1) + "/" + Fecha.getFullYear());
    var sep = sFecha.indexOf('/') != -1 ? '/' : '-';
    var aFecha = sFecha.split(sep);
    var fecha: any = aFecha[2] + '/' + aFecha[1] + '/' + aFecha[0];
    fecha = new Date(fecha);
    fecha.setDate(fecha.getDate() + parseInt(d));
    var anno = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    mes = (mes < 10) ? ("0" + mes) : mes;
    dia = (dia < 10) ? ("0" + dia) : dia;
    var fechaFinal = dia + sep + mes + sep + anno;
    return (fechaFinal);
  }
  
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

}
