import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
//import { Kushki } from '@kushki/js';
import { tap, catchError, map } from 'rxjs/operators'; 

import { Global } from './global';

@Injectable()

export class GeneralService {

    public json;
    public url: string;
    public identity;
    public response;

    //Cuando lleva el signo dolar se indica que es un observable
    public momento$ = new EventEmitter<string>();
    public solicitud$ = new EventEmitter<number>();
    public datos_solicitud$ = new EventEmitter<object>();
    public tipo_solicitud$ = new EventEmitter<string>();
    //public solicitud_al_pagar$ = new EventEmitter<number>();

    constructor(
        public _http: HttpClient,
        //private kushki: Kushki
    ) {
        this.json = Global.json;
        this.url = Global.url;
    }

    dataJson(parameters, serviceID) {

        let inputParameters = [];

        if(Array.isArray(parameters)){

            for (let obj in parameters) {

                for (let i in parameters[obj]) {
                    
                    inputParameters.push({ 'name': i, 'value': parameters[obj][i] });
                }
            }

        } else {
           for (let i in parameters) {
                inputParameters.push({ 'name': i, 'value': parameters[i] });
            }
        }

        this.json.body.inputParameters = inputParameters;
        this.json.body.serviceID.id = serviceID;

        return this.json;
        
    }

    api(json): Observable<any> {

        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(Global.urlServer+'/admin_dev.php/creditosrest/exec', json, { headers: headers });

    }

    getConvenio(json): Observable<any> {
        
        let headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this._http.post(Global.urlServer+'/admin_dev.php/creditosrest/exec', json, { headers: headers });
    
    }

    getIdentity() {

        let identity = JSON.parse(sessionStorage.getItem('identity'));
    
        if(identity && identity != undefined) {
    
          this.identity = identity;
    
        } else {
    
          this.identity = null;
    
        }
    
        return this.identity;
    
    }

    getSolicitud() {
        let solicitud = JSON.parse(sessionStorage.getItem('solicitud'));
        return solicitud
    }

    validateUser() {

        if(this.identity.numero_cuenta == '' || this.identity.numero_cuenta == null) {
            return false;
        } else {
            return true;
        }

    }

    dateFormated(fecha) {
        
        let fecha_split = fecha.split('/');

        let fecha_definitiva = fecha_split[2]+'-'+fecha_split[1]+'-'+fecha_split[0];

        return fecha_definitiva;
    }

    /* kushkiRequestPseBankList() {
        var callback = function(response) {
            if(!response.code){
              console.log(response);
            } else {
              console.error('Error: ',response.error, 'Code: ', response.code, 'Message: ',response.message);
            }
          }
          
          this.kushki.requestPseBankList(callback); // Also you can set the function directly
    } */
  
    apiServicios(json): Observable<any> {
       
        /*var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic bW9uaWh1YjpNb25paHViLjIwMjA=");
        myHeaders.append("Content-Type", "application/json");
        
        return from(
        fetch(
            Global.urlServicios,
              {
                body: JSON.stringify(json),
                headers:myHeaders,
                method: 'POST',
                //mode: 'no-cors',
                redirect: 'follow'
              }
        ).then(response => response)
        .then(result => console.log(result))
        .catch(error => console.log('error', error))
        );*/

        /*return this._http.post(Global.urlServicios, json)
                .pipe(
                  tap ( ( resp: any ) => {

                    this.response = JSON.parse(resp.body.outputParameters.value);

                    //this.validateError();

                    //localStorage.setItem( 'token', this.response.token );

                  })
                );*/

        let headers = new HttpHeaders()
        .set('Content-Type', 'application/json');
        //.set('access-control-allow-origin',"http://localhost:4200/");

        return this._http.post(Global.urlServer+'/admin_dev.php/creditosrest/exec', json,{ headers:headers });                
    }

}