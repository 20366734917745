import { Component, OnInit, OnDestroy } from '@angular/core';
import { Kushki } from '@kushki/js';

import { Global } from '../../../../../services/global';
import { GeneralService } from 'src/app/services/general.service';
import { Subscription } from 'rxjs';
/* import { parse } from 'url';
import { link } from 'fs';
 */
@Component({
  selector: 'app-cash-in',
  templateUrl: './cash-in.component.html',
  styleUrls: ['./cash-in.component.css']
})
export class CashInComponent implements OnInit {

  private kushki: Kushki;
  public identity;
  public full_name;
  public full_surname;
  public description;
  public loading_payment: boolean;
  public text_btn: string;
  public solicitud: number;
  public valor;
  public fecha_hoy: string;
  public mensaje_dia_no: string;
  public btn_payment_enable: boolean;
  public valor_string: string;
  public valor_html: string;
  public validate_mount: boolean;

  public solicitudSuscription: Subscription;

  constructor(
    private _generalService: GeneralService,
  ) {

    this.identity = this._generalService.getIdentity();
    this.full_name = this.identity.primer_nombre + ' ' + this.identity.segundo_nombre;
    this.full_surname = this.identity.primer_apellido + ' ' + this.identity.segundo_apellido;
    this.text_btn = "Pagar";
    this.loading_payment = false;
    //this.valor_html = 0;
    this.solicitud = null;
    this.btn_payment_enable = true;
    this.validate_mount = true;
    this.description = "Pago de cuota credito MoneyTech";

    let fecha = new Date();
    this.fecha_hoy = fecha.getFullYear() + "-" + (fecha.getMonth() + 1) + "-" + fecha.getDate();
    this.kushki = new Kushki({
      merchantId: Global.public_kuski_key/* '476539a5b9d9465db0d55a61136509c0' */,
      inTestEnvironment: false,
    });

  }

  ngOnInit() {

    /* this.solicitudSuscription = this._generalService.solicitud$.subscribe( solicitud => {
      this.solicitud = solicitud;
      console.log("eFECTIVO= "+this.solicitud);
    }); */

    this.solicitud = parseInt(sessionStorage.getItem('solicitud'));
    sessionStorage.removeItem('solicitud');
    this.getValor();

  }

  getValor() {
    let json = Global.json;
    json.body.serviceID.id = '44';
    json.body.inputParameters.push({ 'name': 'solicitud', 'value': this.solicitud });

    this._generalService.api(json).subscribe(
      response => {
        if (response) {
          this.valor = JSON.parse(response.body.outputParameters.value);
          this.valor_html = this.valor.valor;

          this.valor_string = this.valor.valor.toString();
          this.valor_string = this.valor_string.replace(/\,/g, '');
        }
      },
      error => {
        console.log(<any>error)
      }
    );

  }

  initKushki() {
    
    var link_transacciones = document.getElementById('transacciones');
    link_transacciones.hidden = true;

    this.valor_string = this.valor.valor.toString();
    this.valor_string = this.valor_html.replace(/\,/g, '');
    
    var spinner = document.getElementById('spinner');
    spinner.hidden = false;

    this.text_btn = "Cargando";

    this.loading_payment = true;

    var callback = this.callbackResponseCashInit.bind(this);
    
    this.kushki.requestCashToken({
      name: this.full_name,
      lastName: this.full_surname,
      identification: /* '1000000000', */  this.identity.identificacion,
      documentType: this.identity.tipo_identificacion_abreviatura,
      email: this.identity.email,
      totalAmount: parseInt(this.valor_string),
      currency: 'COP',
      description: this.description,
    }, callback); // Also you can set the function directly 


  }

  callbackResponseCashInit(response) {

    let hoy = new Date();
    let semanaEnMilisegundos = 1000 * 60 * 60 * 24 * 5;
    let suma = hoy.getTime() + semanaEnMilisegundos; //getTime devuelve milisegundos de esa fecha
    let fechaDentroDeCincoDias = new Date(suma);
    let fechaString = fechaDentroDeCincoDias.toISOString();
    let fecha_split = fechaString.split('T');

    var spinner = document.getElementById('spinner');
    var btn_payment = document.getElementById('btn-payment');

    if (!response.code) {

      let param = {
        "token": response.token,
        "expirationDate": fecha_split[0]+" 21:59:59",
        "amount": {
          "subtotalIva": 0,
          "subtotalIva0": parseInt(this.valor_string),
          "iva": 0,
          "extraTaxes": {
            "propina": 0
          }
        }
      }

      /* "metadata": {
        "solicitud": this.solicitud,
        "persona": this.identity.persona,
        "bank_name": this.bank_name,
        "description": this.description
      } */

      let body_mh = {
        'solicitud': this.solicitud,
        'persona': this.identity.persona
      };

      let json = Global.json;
      json.body.serviceID.id = '42';
      json.body.inputParameters.push({ 'name': 'body_mh', 'value': body_mh });
      json.body.inputParameters.push({ 'name': 'body', 'value': param });
      json.body.inputParameters.push({ 'name': 'solicitud', 'value': this.solicitud });

      this._generalService.api(json).subscribe(

        response => {
          if (response) {
            if (response.body) {
              let outputParameters = JSON.parse(response.body.outputParameters.value);
              if (outputParameters.status && outputParameters.status == 'success') {

                btn_payment.textContent = "Pago realizado";
                let alert = document.getElementById('success');
                alert.hidden = false;

              } else {

                let alert = document.getElementById('warning');
                alert.hidden = false;

              }

              spinner.hidden = true;
            } else {

              spinner.hidden = true;
              let alert = document.getElementById('warning');
              alert.hidden = false;

            }
          } else {
            spinner.hidden = true;
            let alert = document.getElementById('danger');
            alert.hidden = false;
          }
          btn_payment.hidden = true;
        }, error => {
          btn_payment.hidden = true;
          spinner.hidden = true;
          let alert = document.getElementById('danger');
          alert.hidden = false;
          console.log(<any>error);

        }
      );

    } else {

      spinner.hidden = true;
      let alert = document.getElementById('danger');
      alert.hidden = false;
      console.error('Error: ', response.error, 'Code: ', response.code, 'Message: ', response.message);

    }
  }

  format(input) {
    var num = input.value.replace(/\,/g, '');

    if(parseInt(num) >= parseInt(this.valor_string)) {
      this.validate_mount = true;
    } else {
      this.validate_mount = false;
    }
    if (!isNaN(num)) {
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\,?)(\d{3})/g, '$1,');
      num = num.split('').reverse().join('').replace(/^[\,]/, '');
      input.value = num;
    }

  }

  clean(e) {
    this.validate_mount = false;
    //e.value = "0";
    this.valor_html = ""; 
    let valor_por_defecto = document.getElementById('valor-por-defecto');
    valor_por_defecto.hidden = false;

    let checkbox_value_default = document.getElementById('default') as HTMLInputElement;
    checkbox_value_default.removeAttribute('disabled');
    checkbox_value_default.checked = false;

  }

  checkValue(e) {
    let event = e.target;
    
    if(event.checked) {
      this.valor_html = this.valor.valor;
      this.validate_mount = true;
      e.target.setAttribute('disabled', 'true');
    }
  }

}
