export class Carro {

    constructor( 
        public placa: string,
        public licencia_de_transito: string,
        public marca: string,
        public clase: string,
        public modelo: string,
        public linea: string,
        public valor_comercial: number,
        public validar: number
    ) {}

}