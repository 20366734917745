import { Component, OnInit } from '@angular/core';
import { Global } from '../../../../services/global';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-main-simulator',
  templateUrl: './main-simulator.component.html',
  styleUrls: ['./main-simulator.component.css']
})

export class MainSimulatorComponent implements OnInit {

  public tipo_solicitud;
  public identity;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _generalService: GeneralService
  ) {
    this.identity = _generalService.getIdentity();
  }

  ngOnInit() {
    this.tabConstruct();
  }

  tabConstruct() {

    let tabs = Array.prototype.slice.apply(document.querySelectorAll('.tab'));
    let panels = Array.prototype.slice.apply(document.querySelectorAll('.panel'));

    document.getElementById('tabs').addEventListener('click', e => {

      const target = e.target as HTMLTextAreaElement;

      if (target.classList.contains('tab')) {
        this.tipo_solicitud = target.id;
        this._generalService.tipo_solicitud$.emit(target.id);

        let i = tabs.indexOf(e.target);

        /*  SE COMENTA POR NUEVAS REGLAS DE DISEÑO - CAMBIAR COLO DE LA PESTAÑA AL COLO DEL TIPO DE SOLICITUD
            tabs.map(tab => tab.classList.remove('active'));
            tabs[i].classList.add('active');
        */

        if(this.tipo_solicitud == 'cash') {
          tabs.map(tab => tab.classList.remove('active-car'));
          tabs[i].classList.add('active-cash');
        }

        if(this.tipo_solicitud == 'car') {
          tabs.map(tab => tab.classList.remove('active-cash'));
          tabs[i].classList.add('active-car');
        }

        panels.map(panel => panel.classList.remove('active'));
        panels[i].classList.add('active')

      }
    });

    let dest = location.hash.split('#');

    //SI EN LA RUTA ESTA MARCADA POR UN # SEGUIDO DEL INDICADOR DE LA SOLICITUD CASH / CAR
    if (dest[2]) {

      //Selecciona el tab para luego hacer un evento click
      let tab_dest = document.getElementById(dest[2]);

      if (tab_dest) {

        if (this.identity) {
          this._generalService.tipo_solicitud$.emit(dest[2]);
        }

        tab_dest.click();
      }

    } else {

      let tab_dest = document.getElementById('cash');

      if (tab_dest) {

        if (this.identity) {
          this._generalService.tipo_solicitud$.emit('cash');
        }

        tab_dest.click();

      }

    }

  }

}

