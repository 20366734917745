export var Global = {
  urlServicios: 'http://localhost/servicios',
	url: 'http://localhost/monihub/web',
  urlServer: 'https://electroventas.monihub.com',
  //urlServerTest: 'http://localhost/monihub/web',
  urlServerTest: 'https://electroventas.monihub.com',
  public_kuski_key: "476539a5b9d9465db0d55a61136509c0", // PRUEBAS 23fcc7f0c19343bf91802e50937165c2
  private_kuski_key: "00a836e9bb344cc8a34a34d7b0271e2c", // PREUBAS 1f54a4e8d9704eaeafe05ba3f9099279
  json: 
  {    
    "header":{
      "licenceKey":{
        "token":""
      },
      "guid":{
        "id":"1"
      },
      "messages":[]
    },
  
    "body":{
      "serviceID": {
        "id": "",
        "version": "1"
    },
      "inputParameters":[],
      "outputParameters": []
    }
  },
  formatNumber: {
      separador: ".", // separador para los miles
      sepDecimal: ',', // separador para los decimales
      formatear: function (num) {
        num += '';
        var splitStr = num.split('.');
        var splitLeft = splitStr[0];
        var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
        var regx = /(\d+)(\d{3})/;
        while (regx.test(splitLeft)) {
          splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
        }
        return this.simbol + splitLeft + splitRight;
      },
      new: function (num, simbol) {
        this.simbol = simbol || '';
        return this.formatear(num);
      }
    },
    formatDate:{ 
    formatDate(date) {
      var monthNames = [
        "Enero", "Febrero", "Marzo",
        "Abril", "Mayo", "Junio", "Julio",
        "Augosto", "Septiembre", "Octubre",
        "Noviemmbre", "Dicciembre"
      ];

      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }
},fechaUnix(fecha:string){

  let fecha_split:any = fecha.split('-');

  var datum = new Date(Date.UTC(fecha_split[0],fecha_split[1],fecha_split[2],0,0,0));
  return datum.getTime()/1000;
}

}