export class ActividadEconomica {
    constructor(
        /* public convenio_info_laboral: number, */
        public fecha_ingreso: Date,
        public direccion_empresa: string,
        public ciudad_info_laboral: number,
        public telefono_empresa: number,
        public tipo_contrato: number,
        public cargo_actual: string,
        public tiempo_empresa: string = 'nada'
    ) {}
}