import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { MomentModule } from 'ngx-moment';
/* import {MaterialModule} from '@angular/material'; */

import { UserModule } from './modules/user/user.module';
import { HomeModule } from './modules/home/home.module';
import { SignatureModule } from './modules/signature/signature.module';
import { SimulatorsModule } from './modules/simulators.module';

import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MomentModule,
    UserModule,
    HomeModule,
    SignatureModule,
    SimulatorsModule,
    /* MaterialModule */
    //SimulatorComponent,
    //SimulatorCarComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
