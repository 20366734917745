export class PersonaDatosPersonalCuenta {

    constructor(
        public tipo_documento: any,
        public documento: number,
        public primer_nombre: string,
        public primer_apellido: string,
        public segundo_nombre: string,
        public segundo_apellido: string,
        public email: string,
        public fecha_nacimiento: Date,
        public expedicion_documento: Date,
        public celular: number,
       /*  public convenio: number, */
       public persona: number,
       public asesor: number = 0

    ) {}

}