import { Component, OnInit } from '@angular/core';
import { GenerateOtp } from 'src/app/models/GenrateOtp';

import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActivationComponent implements OnInit {

  public otp: GenerateOtp;
  public identity;
  public nombre;

  constructor(
    private _router: Router,
    private _generalService: GeneralService
  ) { 

    this.identity = this._generalService.getIdentity();

    this.nombre = this.identity.primer_nombre+' '+this.identity.segundo_nombre+' '+this.identity.primer_apellido+' '+this.identity.segundo_apellido;

    /* this.otp = new GenerateOtp(
      this.identity.identificacion,
      this.identity.primer_nombre,
      this.identity.primer_apellido,
      this.identity.celular,
      'validar_usuario',
      this.identity.persona,
      null
    ); */

  }

  ngOnInit() {
  }

}
